<div class="container w-80 w-mobile-100 mw-mobile-100">
    <div class="row justify-content-center">
        <div class="col-12 col-md-7 col-lg-4  text-center my-5 align-self-center"><img class="w-100 h-auto" src="../../../assets/images/LOGO-NaosClub1@2x.png" /></div>
    </div>
    <div class="row" style="text-align: center">
        <div class="col-md-12 table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="3" style="color: white; background-color: #637691">RESPONSABLE DEL TRATAMIENTO: NAOS SKIN CARE SPAIN, S.L.U. (“NAOS”)<br>
                            Avenida Diagonal nº 538, 1º, 08006 Barcelona (Barcelona)
                            </th>
                    </tr>
                    <tr>
                        <th>Tratamiento</th>
                        <th>Finalidades del Tratamiento</th>
                        <th style="width: 100px">Derechos RGPD y contacto</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Gestión de la afiliación al programa de fidelización NAOS CLUB
                        </td>
                        <td>
                            <ul>
                                <li>Tramitar la afiliación de los clientes al programa de fidelización NAOS CLUB.</li>
                                <li>Gestionar la participación de los afiliados en las promociones exclusivas derivadas de la afiliación al programa de fidelización NAOS CLUB (sorteos, concursos, eventos, etc.).</li>
                                <li>Aplicar a los afiliados las ventajas exclusivas derivadas de la afiliación al programa de fidelización NAOS CLUB (acumulación de Estrellas NAOS, descuentos, ofertas, promociones, etc.).</li>
                                <li>Gestionar reclamaciones, dudas y consultas de los afiliados.</li>
                            </ul>
                        </td>
                        <td rowspan="8">
                            Puedes ejercer los derechos estipulados en el RGPD de acceso, rectificación, oposición, supresión, limitación, portabilidad y oposición al hecho ser objeto de una decisión basada únicamente en el tratamiento automatizado de tus datos personales tal y como se explica en nuestra Política de Privacidad Extendida, donde también puedes saber más sobre cómo tratamos tus datos personales, disponible en nuestra web: <a class="linkList" (click)="this.goToSecond()">política de privacidad</a><br>
                            Igualmente, puedes oponerte en todo momento al envío de comunicaciones comerciales por vías electrónicas por nuestra parte comunicándonoslo a la dirección de correo <a href="mailTo:protecciondatos@es.naos.com">protecciondatos@es.naos.com</a> o siguiendo las instrucciones que para ello encontrarás al final de cada una de dichas comunicaciones.<br>
                            Para cualquier duda o información adicional puedes ponerte en contacto con nosotros a través de la siguiente dirección de correo electrónico: <a href="mailTo:protecciondatos@es.naos.com">protecciondatos@es.naos.com</a>.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Análisis, segmentación y perfilado de los afiliados a NAOS CLUB
                        </td>
                        <td>
                            <ul>
                                <li>Analizar y segmentar las características, datos, gustos, intereses, preferencias, historial de compra de los productos y patrones de uso o aprovechamiento de las ventajas del programa de fidelización por parte de los afiliados con el fin de obtener perfiles comunes de afiliados.</li>
                                <li>Enriquecer los perfiles de los afiliados con datos obtenidos de los mismos de otras fuentes o canales de comunicación con NAOS (reclamaciones, formulario de contacto, contacto en rrss, etc.).</li>
                                <li>Diseñar promociones y ventajas exclusivas (eventos, sorteos, concursos, descuentos, ofertas, etc.) y comunicaciones comerciales y publicitarias para su envío por vías electrónicas de forma personalizada en base a los resultados de las acciones de análisis, segmentación y perfilado.</li>
                                <li>Recomendar a los afiliados de forma personalizada los productos de las marcas BIODERMA e INSTITUT ESTHEDERM más adecuados según los resultados de las acciones de análisis, segmentación y perfilado.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Envío de comunicaciones comerciales personalizadas por vías electrónicas
                        </td>
                        <td>
                            <ul>
                                <li>Enviar comunicaciones comerciales y con fines promocionales sobre productos, servicios, novedades, eventos exclusivos y demás información de NAOS por vías electrónicas (sms, email, etc.) personalizadas en base a los resultados del análisis, segmentación y perfilado de los afiliados.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Lectura de los productos BIODERMA/INSTITUT ESTHEDERM en los tickets de compra 
                        </td>
                        <td>
                            <ul>
                                <li>Registrar los productos BIODERMA y/o INSTITUT ESTHEDERM efectivamente adquiridos por el afiliado según el ticket de compra y asignar las estrellas correspondientes conforme al programa de puntos de NAOS CLUB. </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Almacenamiento temporal de los ticket de compra
                        </td>
                        <td>
                            <ul>
                                <li>Disponer del tickets de compras subidos por los afiliados poder tramitar las reclamaciones de los afiliados en los casos en que el proceso de lectura del ticket de compra no sea posible, contrastando así el contenido del ticket de compra subido por el afiliado con el proceso de lectura del mismo realizado por NAOS.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Conservación y publicación de valoraciones de productos NAOS
                        </td>
                        <td>
                            <ul>
                                <li>Publicar las valoraciones de los afiliados de los productos de las marcas BIODERMA e INSTITUT ESTHEDERM en las páginas webs corporativas de BIODERMA e INSTITUT ESTHEDERM.</li>
                                <li>Analizar y estudiar las valoraciones de los afiliados de los productos de las marcas BIODERMA e INSTITUT ESTHEDERM para mejorar la calidad de los productos.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Uso de herramientas de diagnóstico de la piel
                        </td>
                        <td>
                            <ul>
                                <li>Realizar un análisis de las características de la piel de los afiliados y, en base a los resultados del mismo, recomendarles productos de las marcas titularidad de NAOS.</li>
                                <li>Gestionar cualquier duda o incidencia de los afiliados en relación con el diagnóstico de la piel realizado.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Mantenimiento de listas de supresión
                        </td>
                        <td>
                            <ul>
                                <li>Mantener en una lista de supresión con la confirmación de aquellos afiliados que hayan solicitado la supresión de sus datos personales o la oposición al tratamiento de sus datos personales (por ejemplo, al envío de newsletters).</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" style="text-align: center">
        <div class="col-md-12">
            <button class="btn btnSendTicket" (click)="this.goToAfter()">
                Volver
            </button>
        </div>
    </div>
</div>