<div class="container mw-80 mt-3 w-mobile-100 mw-mobile-100">
    <div class="row" *ngIf="!this.loader">
        <div class="col-12 text-center">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>
</div>

<!-- <div *ngIf="this.loader" class="container mw-80 profile-user-data w-mobile-100 mw-mobile-100">
    <div class="row justify-content-end">
        <div class="col-auto text-right pr-0">
            <div class="profile-user-data-icon py-2 px-3 py-md-3 px-md-4"> <i class="far fa-user"></i></div>
        </div>
    </div>
    <div class="row mx-0 mx-md-4 mt-3">
        <div class="col"> <label class="profile-user-blue-text">{{ this.infoClient?.firstname}}
                {{ this.infoClient.surname}}</label>
        </div>
    </div>
    <div class="row mx-0 mx-md-4 justify-content-between mb-4">
        <div class="col-lg-auto"><label class="profile-user-grey-text"> {{ this.infoClient.email}}</label></div>
        <div class="col-lg-auto"> <label class="profile-user-grey-text smaller">Consigue 20* al completar Tu
                Perfil.</label></div>
    </div>
    <div class="row mx-0 mx-md-4">
        <div class="col-lg-6 mb-2 order-1 order-lg-1">
            <img class="profile-user-star" src="../../../assets/images/Group@3x.png" /><label class="profile-user-blue-text smaller"> &nbsp;&nbsp;Tus estrellas
                {{this.scoreClient | number:'1.0-3':'de'}}</label>
        </div>
        <div class="col-lg-6 mb-2 order-1 order-lg-1 mt-2">
            <ng-container *ngIf="this.infoAdvServePrivateMGM !== null">
                <div class="row">
                    <div class="col-2 hidenTabletMobile"></div>
                    <div class="col-10">
                        <div *ngIf="this.infoAdvServePrivateMGM.offers[0].accomplishment_quantity < this.infoAdvServePrivateMGM.offers[0].max_accomplishment_quantity" class="inputfriendscode tooltip-primary" (click)="this.copyMessage(this.infoClient.public_code)" ngbTooltip="Copiado"
                            [autoClose]="false" triggers="manual" #t="ngbTooltip" (click)="t.open()" (mouseleave)="t.close()">
                            Código amig@: {{this.infoClient.public_code}} <i class="far fa-copy"></i>
                        </div>
                        <div *ngIf="this.infoAdvServePrivateMGM.offers[0].accomplishment_quantity >= this.infoAdvServePrivateMGM.offers[0].max_accomplishment_quantity" class="inputfriendscodecomplete tooltip-primary" ngbTooltip="Copiado" [autoClose]="false" triggers="manual"
                            #t="ngbTooltip">
                            Código amig@: {{this.infoClient.public_code}}
                        </div>
                    </div>
                    <div class="colInfoFriendCode">
                        <i [placement]="['right', 'bottom', 'auto']" ngbPopover="Comparte este código con tus amig@s. Cuando usen tu código al registrarse en NAOS Club, conseguirás 20* por amig@. Máximo hasta 10 amig@s (200* = 10€) ¡Viva la amistad!" class="fas fa-info-circle iconInfo"></i>
                    </div>
                </div>
                <div class="row progressFriend withoutMargin">
                    <div class="col-2 hidenTabletMobile"></div>
                    <div class="col-md-5 col-sm-12 col-xs-12 lineCountFriendCode">
                        <div class="progress">
                            <div class="progress-bar bg-info" role="progressbar" [ngStyle]="{width: this.infoAdvServePrivateMGM.offers[0].accomplishment_quantity * 10 + '%'}" aria-valuenow="{{this.infoAdvServePrivateMGM.offers[0].accomplishment_quantity}}" aria-valuemin="0" aria-valuemax="{{this.infoAdvServePrivateMGM.offers[0].max_accomplishment_quantity}}"></div>
                        </div>
                    </div>
                    <div class="col-md-5 col-sm-12 col-xs-12 lineCountFriendCode">
                        <span class="countFriendsCode">{{this.infoAdvServePrivateMGM.offers[0].accomplishment_quantity}}/{{this.infoAdvServePrivateMGM.offers[0].max_accomplishment_quantity}} amig@s registrados</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="row mx-0 mx-md-4 mt-3 mt-md-5 mb-5 justify-content-end">
        <div class="col-lg-6 mb-2 order-1 order-lg-1">
            <i *ngIf="this.infoClient.whereid_1 == ''" class="fa fa-map-marker iconoSitio" aria-hidden="true"></i>
            <label *ngIf="this.infoClient.whereid_1 == ''" class="ml-2 profile-basic-text">Provincia farmacia origen</label>
            <i *ngIf="this.infoClient.whereid_1 == ''" [placement]="['right', 'bottom', 'auto']"
                ngbPopover="La provincia de la farmacia origen es el territorio donde se localiza la farmacia donde obtendrás el doble de estrellas por tus compras. En caso que sea una farmacia online, por favor selecciona el valor 'Farmacia online'"
                class="fas fa-info-circle iconInfo"></i>
            <div class="form-group" *ngIf="this.infoClient.whereid_1 == ''">
                <select class="form-control cursor-pointer input-select-naos" (change)="this.selectSitesPharmacy()" [(ngModel)]="this.valueProvinceImportantPharmacy" id="exampleFormControlSelect1">
                    <option value=""> </option>
                    <option value="XX">FARMACIA ONLINE</option>
                  <option *ngFor="let province of this.listProvinces" [value]="province.cdprovincia">{{province.dcprovincia}}</option>
                </select>
            </div>
        </div>
        <div class="col-lg-6 mb-2 order-3 order-lg-2">
            <i class="fa fa-map-marker iconoSitio" *ngIf="this.infoClient.whereid_2 == ''" aria-hidden="true"></i>
            <label *ngIf="this.infoClient.whereid_2 == ''" class="ml-2 profile-basic-text">Provincia centro de estética origen </label>
            <i *ngIf="this.infoClient.whereid_2 == ''" [placement]="['right', 'bottom', 'auto']"
                ngbPopover="La provincia del centro de estética origen es el territorio donde se localiza el centro de estética donde obtendrás el doble de estrellas por tus compras. En caso que sea un centro de estética online, por favor selecciona el valor 'Centro de estética online'"
                class="fas fa-info-circle iconInfo"></i>
            <div class="form-group">
                <select *ngIf="this.infoClient.whereid_2 == ''" class="form-control cursor-pointer input-select-naos" (change)="this.selectSitesEstetica()" [(ngModel)]="this.valueProvinceImportantEstetica" id="exampleFormControlSelect1">
                        <option value=""> </option>
                        <option value="XX">CENTRO DE ESTÉTICA ONLINE</option>
                    <option *ngFor="let provincee of this.listProvinces" [value]="provincee.cdprovincia">{{provincee.dcprovincia}}</option>
                </select>
            </div>
        </div>
        <div class="col-lg-6 mb-2 order-2 order-lg-3">
            <i class="fa fa-map-marker iconoSitio" aria-hidden="true"></i>
            <label class="mx-2 profile-basic-text">Farmacia Origen </label>
            <img *ngIf="this.loaderFarmcia" class="loaderSite" src="../../../assets/images/loader.gif" />
            <i [placement]="['right', 'bottom', 'auto']"
                ngbPopover="Campo Opcional. En tu farmacia origen obtendrás el doble de estrellas por tus compras. Una vez seleccionada una farmacia origen no se puede modificar, salvo situación excepcional. Para ello puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com"
                class="fas fa-info-circle iconInfo"></i>
            <div class="form-group" *ngIf="this.infoClient.whereid_1 == ''">
                <div class="dropdown" *ngIf="!this.showPharmacyInput">
                    <div id="myDropdownPharmacy" class="dropdown-content">
                        <div class="cerrarInputSearchDiv">
                            <a (click)="this.closedInputSearchPharmacy()" class="cerrarInputSearch">Cerrar</a>
                        </div>
                        <input type="text" placeholder="Buscar.." id="myInputPharmacy" (keyup)="this.filterFunctionPharmacy()" [(ngModel)]="this.stringNamePharmacy">
                        <a *ngFor="let itempharmacy of this.listadoSitiosPharmacy" (click)="this.setSelectedSitePharmacy(itempharmacy.where_id, itempharmacy.fullName)">{{itempharmacy.fullName}}</a>
                    </div>
                </div>
                <div *ngIf="this.showPharmacyInput">
                    <input type="text" [(ngModel)]="this.stringNamePharmacy" class="form-control" disabled>
                </div>
            </div>
            <input *ngIf="this.infoClient.whereid_1 !== ''" type="text" class="form-control" [value]="this.stringNamePharmacy" id="farmaciaOrigen" disabled>
        </div>
        <div class="col-lg-6 mb-2 order-4 order-lg-4">
            <i class="fa fa-map-marker iconoSitio" aria-hidden="true"></i><label class="mx-2 profile-basic-text">Centro de Estética Origen
            </label><img *ngIf="this.loaderEstetica" class="loaderSite" src="../../../assets/images/loader.gif" /><i [placement]="['right', 'bottom', 'auto']" ngbPopover="Campo Opcional. En tu centro de estética origen obtendrás el doble de estrellas por tus compras. Una vez seleccionado un centro de estética origen no se puede modificar, salvo situación excepcional. Para ello puedes ponerte en contacto con nosotros en esta dirección: infonaosclub@es.naos.com"
                class="fas fa-info-circle iconInfo"></i>
            <div class="form-group" *ngIf="this.infoClient.whereid_2 == ''">
                <div class="dropdown" *ngIf="!this.showEsteticaInput">
                    <div id="myDropdownEstetica" class="dropdown-content">
                        <div class="cerrarInputSearchDiv">
                            <a (click)="this.closedInputSearchEstetica()" class="cerrarInputSearch">Cerrar</a>
                        </div>
                        <input type="text" placeholder="Buscar.." id="myInputEstetica" (keyup)="this.filterFunctionEstetica()" [(ngModel)]="this.stringNameEstetica">
                        <a *ngFor="let itemestetica of this.listadoSitiosEstetica" (click)="this.setSelectedSiteEstetica(itemestetica.where_id, itemestetica.fullName)">{{itemestetica.fullName}}</a>
                    </div>
                </div>
                <div *ngIf="this.showEsteticaInput">
                    <input type="text" [(ngModel)]="this.stringNameEstetica" class="form-control" disabled>
                </div>
            </div>
            <input *ngIf="this.infoClient.whereid_2 !== ''" type="text" class="form-control" [value]="this.stringNameEstetica" id="esteticaOrigen" disabled>
        </div>
    </div>
    <div class="row mx-0 mx-md-4 justify-content-end ">
        <div class="col text-right mb-3"> <a class="profile-user-silver-text cursor-pointer" data-toggle="modal" data-target="#bajaModal">Darse de baja</a></div>
    </div>
</div> -->

<!-- Body -->
<div *ngIf="this.loader" class="container-lg mw-80 mt-3 mt-sm-5 w-mobile-90 mw-mobile-90 boardProfile">

    <div class="row justify-content-around mb-3 text-center">
        <div class="col-sm-8 col-md-5 col-lg-5 nav-profile-option selected p-2" (click)="this.clickOpt(1)" id="optprofile1">
            <label class="mb-0 mt-2 cursor-pointer">DATOS&nbsp;</label>
            <label class="cursor-pointer">PERSONALES</label>
        </div>
        <div class="col-sm-8 col-md-5 col-lg-5 nav-profile-option p-2" (click)="this.clickOpt(2)" id="optprofile2">
            <label class="mb-0 mt-2 cursor-pointer">DATOS&nbsp;</label>
            <label class="cursor-pointer">DOMICILIO </label>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 text-body-profile">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<!-- Body -->

<!-- pop-ups avisos-->
<!-- <div class="modal fade" id="bajaModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Baja de NAOS Club</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <p>Estás a punto de borrar tu cuenta de forma permanente. Ten en cuenta que no podrás volver a activarla ni recuperar ningún dato o contenido que hayas subido a tu cuenta.<br> Selecciona por favor el motivo de esta solicitud :</p><br>
                        <div class="form-check" *ngFor="let item of this.listReason">
                            <input *ngIf="item.text !== 'Otro'" class="form-check-input" [(ngModel)]="item.valueModel" type="checkbox" [value]="item.value" [id]="item.valueid">
                            <input *ngIf="item.text === 'Otro'" class="form-check-input" [(ngModel)]="item.valueModel" (click)="this.changeStateCommentBaja()" type="checkbox" [value]="item.value" [id]="item.valueid">
                            <label class="form-check-label" [for]="item.valueid">
                                {{item.text}}
                            </label>
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" [(ngModel)]="this.commentBaja" rows="5" id="comment" [disabled]="!this.disabledCommentBajaValue"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btnEnviarForms" (click)="this.sendBajaClient()">Eliminar mi
                    cuenta</button>
            </div>
        </div>
    </div>
</div> -->

<div class="modal fade" id="bajaModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Baja de NAOS Club</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <p>Estás a punto de borrar tu cuenta de forma permanente. Ten en cuenta que no podrás volver a activarla ni recuperar ningún dato o contenido que hayas subido a tu cuenta.<br> Selecciona por favor el motivo de esta solicitud :</p><br>
                    <div class="form-check" *ngFor="let item of this.listReason">
                        <input *ngIf="item.text !== 'Otro'" class="form-check-input" [(ngModel)]="item.valueModel" type="checkbox" [value]="item.value" [id]="item.valueid">
                        <input *ngIf="item.text === 'Otro'" class="form-check-input" [(ngModel)]="item.valueModel" (click)="this.changeStateCommentBaja()" type="checkbox" [value]="item.value" [id]="item.valueid">
                        <label class="form-check-label" [for]="item.valueid">
                            {{item.text}}
                        </label>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" [(ngModel)]="this.commentBaja" rows="5" id="comment" [disabled]="!this.disabledCommentBajaValue"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btnEnviarForms" (click)="this.sendBajaClient()">Eliminar mi
                cuenta</button>
        </div>
      </div>
    </div>
  </div>



<!-- <app-naosnotification (avisoText)="this.getMessageNotification($event)" [textUrl]="this.textUrl" [msgModal]="this.textModalProfile"></app-naosnotification> -->
<div class="modal fade" id="exampleModalNotificationProfile" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 filaLogo">
                        <img src="../../../assets/images/LOGO-NaosClub1.png" />
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12 filaLogo2" id="contenidodiv">
                        {{ this.textModalProfile }}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
            </div>
        </div>
    </div>
</div>
