<div class="container w-mobile-100 mw-mobile-100">
    <div class="row justify-content-center">
        <div class="col-12 col-md-7 col-lg-4 text-center my-5 align-self-center"><img class="w-100 h-auto" src="https://lsp-images.s3.eu-west-1.amazonaws.com/f3da8c048aa2e1c3ddc2da150cec8768b960a0d7/pre/webcontent/logo_naosclub.png" /></div>
    </div>
    <div class="row rowButtons justify-content-center py-2 py-sm-5 mb-3">
        <div class="col-6 mt-2 mb-4" style="text-align: right;">
            <span class="buttonLogin">Inicia sesión</span>
        </div>
        <div class="col-6 mt-2 mb-4">
            <span class="buttonRegister" (click)="this.goToRegister()">Regístrate</span>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 col-lg-8">
            <div class="container containerMenuNaos w-100 px-0">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row" style="margin-bottom: 5%">
                            <div class="col-md-12 contenedorDatosRegister">
                                <div class="row mb-2">
                                    <div class="col-md-12 titleDatosRegister">
                                        <h5>Iniciar sesión</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 datosPersonales">
                                        <div class="form-group row" style="margin-bottom: 5%">
                                          <label class="col-sm-3 col-form-label">Email</label>
                                          <div class="col-sm-9">
                                            <input type="email" [(ngModel)]="this.email" name="surname" class="form-control inputRegister" placeholder="Campo obligatorio" id="email" autocomplete="off">
                                          </div>
                                        </div>
                                        <div class="form-group row" style="margin-bottom: 5%">
                                          <label class="col-sm-3 col-form-label">Contraseña</label>
                                          <div class="col-sm-9">
                                            <div class="form-group">
                                              <div class="input-group" id="show_hide_password">
                                                <input class="form-control inputRegister" [(ngModel)]="this.password" name="password" type="password" placeholder="Campo obligatorio" autocomplete="off">
                                                <div class="input-group-addon">
                                                  <a><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row lineButtonLogin">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <button type="submit" (click)="this.doLogin()" class="btn btn-register" id="submitButton" [disabled]="this.disabledButton">Entrar</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row lineButtonLogin">
                                    <div class="col-md-12">
                                        <u><a style="color: #4374a6 !important;" (click)="this.rememberPassword()" class="linkForgivePassword">He olvidado mi contraseña</a></u>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-naosnotification [msgModal]="this.textModal"></app-naosnotification> -->
<div class="modal fade" id="exampleModalNotificationLogin" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 filaLogo">
                <img src="../../../assets/images/LOGO-NaosClub1.png" />
            </div>
          </div><br>
          <div class="row">
              <div class="col-md-12 filaLogo2" id="contenidodiv">
                {{ this.textModal }}
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
  </div>
