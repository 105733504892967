<div class="container">
    <div class="row justify-content-center">
        <div class="col-12 col-md-7 col-lg-4 text-center my-5 align-self-center">
            <img class="w-100 h-auto" src="https://lsp-images.s3.eu-west-1.amazonaws.com/f3da8c048aa2e1c3ddc2da150cec8768b960a0d7/pre/webcontent/logo_naosclub.png" />
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="container containerMenuNaos w-100 px-0">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row" style="margin-bottom: 5%">
                            <div class="col-lg-12 col-md-12 col-xs-12 contenedorDatosRegister">
                                <div class="row mb-4">
                                    <div class="col-md-12 titleDatosRegister">
                                        <h5>Recuperar Contraseña</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 datosPersonales m-4">
                                        <div class="form-group row" style="margin-bottom: 2%">
                                          <label class="col-sm-3 col-form-label">Contraseña* </label>
                                            <label class="col-sm-1 col-form-label"><i [placement]="['right', 'bottom', 'auto']" ngbPopover="La contraseña debe tener una longitud entre 8 y 20 caracteres y contener mayúsculas, minúsculas y números."
                                              class="fas fa-info-circle iconInfo"></i></label>
                                          <div class="col-sm-8">
                                            <div class="form-group">
                                              <div class="input-group" id="show_hide_password">
                                                <input class="form-control inputRegister" [(ngModel)]="this.password" name="password" type="password" autocomplete="off">
                                                <div class="input-group-addon">
                                                  <a><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row" style="margin-bottom: 5%">
                                          <label class="col-sm-3 col-form-label">Repetir contraseña* </label>
                                            <label class="col-sm-1 col-form-label"><i [placement]="['right', 'bottom', 'auto']" ngbPopover="La contraseña debe tener una longitud entre 8 y 20 caracteres y contener mayúsculas, minúsculas y números."
                                              class="fas fa-info-circle iconInfo"></i></label>
                                            <div class="col-sm-8">
                                            <div class="form-group">
                                              <div class="input-group" id="show_hide_password1">
                                                <input class="form-control inputRegister" [(ngModel)]="this.repeatpassword" name="repeatpassword" type="password" autocomplete="off">
                                                <div class="input-group-addon">
                                                  <a><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                                </div>
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row lineButtonLogin">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <button type="submit" (click)="this.validationPassword()" class="btn btn-register">Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalNotificationRemember" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 filaLogo">
                <img src="../../../assets/images/LOGO-NaosClub1.png" />
            </div>
          </div><br>
          <div class="row">
              <div class="col-md-12 filaLogo2" id="contenidodiv">
                {{ this.textModal }}
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
  </div>
