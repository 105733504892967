<div class="container">
  <div class="row" style="text-align: center; margin-top: 5%;">
      <div class="col-md-12">
          <img src="../../../assets/images/LOGO-NaosClub1@1x.png" />
      </div>
  </div><br><br>
  <div class="row rowTitle">
      <div class="col-md-12">
          POLÍTICA DE PRIVACIDAD EXTENDIDA DEL PROGRAMA DE FIDELIZACIÓN NAOS CLUB
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              NAOS SKIN CARE SPAIN, S.L.U. desea poner a disposición de los afiliados al club de fidelización NAOS CLUB (los “Afiliados”), la presente información adicional sobre protección de datos (en adelante, la “Política de Privacidad”), en la que proporcionamos de manera transparente y sencilla toda la información sobre los tratamientos que realizamos con sus datos personales, las finalidades para las cuales los tratamos y los derechos que pueden ejercer. La presente Política de Privacidad estará siempre disponible en las webs corporativas de las marcas INSTITUT ESTHEDERM y BIODERMA.
          </p>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              1. ¿QUIÉNES SOMOS Y CÓMO PUEDES CONTACTAR CON NOSOTROS?
          </p>
          <p>
            La compañía responsable del tratamiento de los datos personales es NAOS SKIN CARE SPAIN S.L.U., (en adelante, “NAOS”), sociedad constituida conforme a la legislación española, con domicilio social en avenida Diagonal nº 538, 1º, 08006 Barcelona (Barcelona), titular de CIF número B-61.424.974, inscrita en el Registro Mercantil de Barcelona al Tomo 30052, Libro B169371, Folio 69, Inscripción 1ª. Si tienes alguna duda acerca de los tratamientos que realizamos de tus datos personales, puedes ponerte en contacto con nuestro Delegado de Protección de Datos (DPO) a la dirección de correo <a href="mailto:protecciondatos@es.naos.com">protecciondatos@es.naos.com</a>.
          </p>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              2. ¿QUÉ SON UN DATO DE CARÁCTER PERSONAL Y UN TRATAMIENTO?
          </p>
          <p>
              Dato de carácter personal es toda información sobre una persona física identificada o identificable. Se considerará persona física identificable toda persona cuya identidad pueda determinarse, directa o indirectamente, en particular mediante un identificador, como, por ejemplo, un nombre, un número de identificación, datos de localización, un identificador en línea o uno o varios elementos propios de la identidad física, fisiológica, genética, psíquica, económica, cultural o social de dicha persona. Un tratamiento de datos de carácter personal es toda operación o conjunto de operaciones que realizamos sobre tus datos personales, como, por ejemplo, la recogida, registro, conservación, utilización y comunicación de sus datos.
          </p>
      </div>
  </div>
  <br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              3. ¿QUÉ DATOS PERSONALES RECOPILAMOS?
          </p>
          <p>
            Para poder realizar los tratamientos de datos que especificamos en la presente Política de Privacidad, necesitamos captar determinada información sobre nuestros Afiliados (en adelante, los “Interesados”). A continuación podrás ver especificados los datos personales que podemos recabar de los Interesados a través de los distintos canales de interacción y comunicación de NAOS y de terceros clasificados por tratamientos:
          </p><br>
          <!-- <ul>
              <li>nombre y apellidos, fecha de nacimiento, género, nacionalidad, documento de identidad (DNI/NIE/Pasaporte) y domicilio completo.</li>
              <li>número de móvil, dirección de correo electrónico.</li>
              <li>características y hábitos personales.</li>
              <li>tipo de piel, evolución, necesidades y estado de la piel, resultados de uso de la herramienta de diagnóstico de la piel.</li>
              <li>historial de compra de productos BIODERMA/INSTITUT ESTHEDERM.</li>
              <li>ticket de compra de productos BIODERMA/INSTITUT ESTHEDERM.</li>
              <li>historial de aprovechamiento del club de fidelización NAOS CLUB y sus ventajas y promociones.</li>
              <li>encuestas de satisfacción, valoraciones y opiniones de productos, y</li>
              <li>cualquier otra información que nos pueda facilitar al ponerse en contacto con nosotros por cualquier vía o canal. </li>
          </ul> -->
          <div class="row" style="text-align: center">
            <div class="col-md-12 table-responsive">
                <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          Gestión de la afiliación al programa de fidelización NAOS CLUB
                        </td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Tramitar la afiliación de los clientes al programa de fidelización NAOS CLUB.</li>
                            <li>Gestionar la participación de los afiliados en las promociones exclusivas derivadas de la afiliación al programa de fidelización NAOS CLUB (sorteos, concursos, eventos, etc.).</li>
                            <li>Aplicar a los afiliados las ventajas exclusivas derivadas de la afiliación al programa de fidelización NAOS CLUB (acumulación de Estrellas NAOS, descuentos, ofertas, promociones, etc.).</li>
                            <li>Gestionar reclamaciones, dudas y consultas de los afiliados.</li>
                          </ul>
                        </td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Nombre y apellidos</li>
                            <li>Fecha de nacimiento</li>
                            <li>Género</li>
                            <li>Domicilio</li>
                            <li>Número de móvil</li>
                            <li>Email</li>
                            <li>Dirección IP</li>
                            <li>Historial de compra</li>
                            <li>Historial de aprovechamiento de NAOS CLUB</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Análisis, segmentación y perfilado de los afiliados a NAOS CLUB</td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Analizar y segmentar las características, datos, gustos, intereses, preferencias, historial de compra de los productos y patrones de uso o aprovechamiento de las ventajas del programa de fidelización por parte de los afiliados con el fin de obtener perfiles comunes de afiliados.</li>
                            <li>Enriquecer los perfiles de los afiliados con datos obtenidos de los mismos de otras fuentes o canales de comunicación con NAOS (reclamaciones, formulario de contacto, contacto en rrss, etc.).</li>
                            <li>Diseñar promociones y ventajas exclusivas (eventos, sorteos, concursos, descuentos, ofertas, etc.) y comunicaciones comerciales y publicitarias para su envío por vías electrónicas de forma personalizada en base a los resultados de las acciones de análisis, segmentación y perfilado.</li>
                            <li>Recomendar a los afiliados de forma personalizada los productos de las marcas BIODERMA e INSTITUT ESTHEDERM más adecuados según los resultados de las acciones de análisis, segmentación y perfilado.</li>
                          </ul>
                        </td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Nombre y apellidos</li>
                            <li>Fecha de nacimiento</li>
                            <li>Género</li>
                            <li>Domicilio</li>
                            <li>Comunicaciones y/o interacción mantenida con NAOS a través de los canales de comunicación e NAOS</li>
                            <li>Historial de compra</li>
                            <li>Historial de aprovechamiento de NAOS CLUB</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Envío de comunicaciones comerciales personalizadas por vías electrónicas</td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Enviar comunicaciones comerciales y con fines promocionales sobre productos, servicios, novedades, eventos exclusivos y demás información de NAOS por vías electrónicas (sms, email, etc.) personalizadas en base a los resultados del análisis, segmentación y perfilado de los afiliados.</li>
                          </ul>
                        </td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Nombre</li>
                            <li>Email</li>
                            <li>Historial de compra</li>
                            <li>Historial de aprovechamiento de NAOS CLUB</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Lectura de los productos BIODERMA/INSTITUT ESTHEDERM en los tickets de compra </td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Registrar los productos BIODERMA y/o INSTITUT ESTHEDERM efectivamente adquiridos por el afiliado según el ticket de compra y asignar las estrellas correspondientes conforme al programa de puntos de NAOS CLUB.</li>
                          </ul>
                        </td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Historial de compra</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Almacenamiento temporal de los ticket de compra</td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Disponer del tickets de compras subidos por los afiliados poder tramitar las reclamaciones de los afiliados en los casos en que el proceso de lectura del ticket de compra no sea posible, contrastando así el contenido del ticket de compra subido por el afiliado con el proceso de lectura del mismo realizado por NAOS.</li>
                          </ul>
                        </td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Nombre y apellidos</li>
                            <li>Imagen de ticket de compra</li>
                            <li>Historial de compra</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Conservación y publicación de valoraciones de productos NAOS</td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Publicar las valoraciones de los afiliados de los productos de las marcas BIODERMA e INSTITUT ESTHEDERM en las páginas webs corporativas de BIODERMA e INSTITUT ESTHEDERM.</li>
                            <li>Analizar y estudiar las valoraciones de los afiliados de los productos de las marcas BIODERMA e INSTITUT ESTHEDERM para mejorar la calidad de los productos.</li>
                          </ul>
                        </td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Nombre y apellidos</li>
                            <li>Historial de compra</li>
                            <li>Dirección IP</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Uso de herramientas de diagnóstico de la piel</td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Realizar un análisis de las características de la piel de los afiliados y, en base a los resultados del mismo, recomendarles productos de las marcas titularidad de NAOS.</li>
                            <li>Gestionar cualquier duda o incidencia de los afiliados en relación con el diagnóstico de la piel realizado.</li>
                          </ul>
                        </td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Nombre y apellidos</li>
                            <li>Tipo de piel</li>
                            <li>Productos recomendados</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Mantenimiento de listas de supresión</td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Mantener en una lista de supresión con la confirmación de aquellos afiliados que hayan solicitado la supresión de sus datos personales o la oposición al tratamiento de sus datos personales (por ejemplo, al envío de newsletters).</li>
                          </ul>
                        </td>
                        <td style="text-align: left !important">
                          <ul>
                            <li>Nombre y apellidos</li>
                            <li>Email</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </div>
          </div>
      </div>
  </div>
  <br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              4. ¿A TRAVÉS DE QUÉ CANALES Y TERCERAS COMPAÑÍAS RECOGEMOS TUS DATOS PERSONALES?
          </p>
          <p>
              Podemos captar información sobre los Interesados directamente de estos últimos a través de las siguientes vías:
          </p>
          <ul>
              <li>Formulario de Registro de NAOS CLUB.</li>
              <li>Uso y aprovechamiento del programa de fidelización NAOS CLUB.</li>
              <li>Uso de todas las funcionalidades que ofrece la cuenta de usuario de NAOS CLUB en la web de NAOS CLUB.</li>
              <li>Herramienta online de diagnóstico de la piel disponible en la web de NAOS CLUB.</li>
              <li>Opiniones, comentarios y valoraciones de producto remitidos por los Afiliados a través de la cuenta de usuario de NAOS CLUB.</li>
              <li>Uso de las herramientas de diagnóstico de la piel.</li>
              <li>Interacción con NAOS mediante los perfiles corporativos de la compañía en redes sociales (como, por ejemplo, Facebook, Instagram o Twitter) y demás canales de comunicación, como las páginas web corporativas de NAOS o nuestro servicio de contacto o atención al cliente.</li>
              <li>Respuestas a encuestas de satisfacción y valoraciones de productos.</li>
          </ul>
      </div>
  </div>
  <br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              5. ¿QUÉ DERECHOS PUEDES EJERCER?
          </p>
          <p class="negrita">
              A) Derecho de acceso
          </p>
          <p>
              Tienes derecho a saber si NAOS está tratando tus datos personales y, en tal caso, conocer qué datos trata.
          </p>
          <p class="negrita">
              B) Derecho de rectificación
          </p>
          <p>
              Tienes derecho a modificar aquellos datos tuyos que sean inexactos o incompletos. Para ello deberás indicar qué datos deseas modificar y acreditarlos adecuadamente.
          </p>
          <p class="negrita">
              C) Derecho de oposición
          </p>
          <p>
              En los supuestos legalmente previstos puedes oponerte en cualquier momento, por motivos relacionados con tu situación particular, a que tratemos tus datos. En el caso concreto el club de fidelización NAOS CLUB, puedes oponerte a que realicemos tratamientos de marketing, publicidad y envío de comunicaciones publicitarias y promocionales por vías electrónicas, comunicándoselo por escrito a nuestro Delegado de Protección de Datos (DPO)  a la dirección de correo <a href="mailTo:protecciondedatos@gruponaos.es">protecciondedatos@es.naos.com</a> o siguiendo las instrucciones que para ello encontrarás al final de cada una de dichas comunicaciones.
          </p>
          <p class="negrita">
              D) Derecho de supresión
          </p>
          <p>
              Tienes derecho a cancelar tus datos personales. Esto no significa que tus datos sean totalmente eliminados, sino que tus datos se conservarán bloqueados de manera que se impida su tratamiento, sin perjuicio de su puesta a disposición de las administraciones públicas, jueces y tribunales para la atención de posibles responsabilidades que hayan surgido como consecuencia del tratamiento durante el plazo de prescripción de estas últimas.
          </p>
          <p class="negrita">
              E) Derecho a la portabilidad de datos
          </p>
          <p>
              Tienes derecho a recibir y/o a transferir a otro responsable del tratamiento diferente de NAOS aquellos datos personales que te incumban y que nos hayas facilitado.
          </p>
          <p class="negrita">
              F) Derecho a la limitación en el tratamiento
          </p>
          <p>
              Tienes derecho a solicitarnos que suspendamos el tratamiento de tus datos cuando (i) hayas impugnado la exactitud de tus datos, mientras NAOS verifica dicha exactitud; o (ii) hayas ejercido tu derecho de oposición al tratamiento de tus datos, mientras se verifica si los motivos legítimos de NAOS prevalecen sobre los tuyos como interesado. Igualmente, este derecho te permite solicitar a NAOS que conserve tus datos personales cuando (i) el tratamiento de datos sea ilícito y como interesado te opongas a la supresión de tus datos, solicitando en su lugar una limitación de su uso; o (ii) NAOS ya no necesite tus datos personales para los fines del tratamiento, pero los necesite para la formulación, ejercicio, o defensa de reclamaciones.
          </p>
          <p class="negrita">
              G) Derecho a no ser objeto de una decisión basada únicamente en tratamientos automatizados que produzca efectos jurídicos o similares en el afiliado
          </p>
          <p>
              Tienes derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado de tus datos, incluida la elaboración de perfiles, cuando dicha decisión produzca efectos jurídicos o contractuales que te afecten. En NAOS realizamos los siguientes tratamientos que podrían ser considerados de esta naturaleza: (a) análisis, segmentación y perfilado de los afiliados a NAOS CLUB; (b) lectura de los productos BIODERMA/INSTITUT ESTHEDERM en los tickets de compra y (c) conservación y publicación de valoraciones de productos NAOS.
          </p><br>
          <p>
              Podrás ejercer tus derechos, mandándole tu petición a nuestro Delegado de Protección de Datos (DPO) a la siguiente dirección de correo electrónico <a href="mailTo:protecciondedatos@gruponaos.es">protecciondedatos@gruponaos.es</a> o bien por correo postal, acreditando en ambos casos tu identidad, dirigido a: NAOS SKIN CARE SPAIN, S.L.U., A/A Delegado de Protección de Datos, Avenida Diagonal nº 538, 1º, 08006 Barcelona (Barcelona). Asimismo, te informamos de que, en el caso de que consideres que no hemos satisfecho correctamente el ejercicio de tus derechos, podrás presentar una reclamación ante la Agencia Española de Protección de Datos (AEPD), dirigiéndote a su página web <a href="http://www.aepd.es">http://www.aepd.es</a>.
          </p>
      </div>
  </div>
  <br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              6. ¿QUÉ TRATAMIENTOS REALIZAMOS SOBRE TUS DATOS PERSONALES?
          </p>
          <p>
              Para poder informarte de forma transparente y con detalle sobre las finalidades para las cuales tratamos tus datos hemos procedido a separar la información relativa a cada tratamiento en cuadros independientes. Así, podrás encontrar toda la información específica de cada uno de los tratamientos que realizamos de tus datos en su correspondiente cuadro de forma individualizada. Cada cuadro descriptivo de los correspondientes tratamientos recoge la siguiente información:
          </p><br>
          <p class="negrita">
              ¿Para qué finalidades tratamos tus datos?
          </p>
          <p>
              En esta columna explicamos con qué finalidad tratamos tus datos personales.
          </p>
          <p class="negrita">
              ¿Sobre qué base legal tratamos tus datos?
          </p>
          <p>
              Esta columna explica el fundamento o justificación legal que nos permite tratar tus datos personales de forma lícita. La normativa de protección de datos requiere que realicemos el tratamiento de tus datos sobre una base o justificación legal que legitime dicho tratamiento. Así, para tratar tu información personal nos podemos basar en distintas bases o justificaciones legales dependiendo del tratamiento que llevemos a cabo de sus datos. Las bases legales para el tratamiento de tus datos personales pueden ser:
          </p>
          <p class="margen">
              <b>Interés legítimo</b>: Como empresa, NAOS puede tener un interés legítimo en realizar determinados tratamientos de los datos personales de los Afiliados. En todo caso, este interés legítimo se basa en un análisis sobre el adecuado equilibrio de nuestros intereses en tratar tus datos de carácter personal y los derechos y libertades de los Afiliados. El resultado de dicho análisis determinará el que podamos utilizar o no tu información personal para los tratamientos descritos en la presente Política de Privacidad.
          </p>
          <p class="margen">
              <b>La ejecución del contrato</b>: Determinados tratamientos de datos personales que NAOS realice pueden ser condición necesaria para dar cumplimiento a las condiciones generales de adhesión al programa de fidelización NAOS CLUB. Con el fin de que poder tramitar la solicitud de adhesión a NAOS CLUB y gestionar el disfrute del citado programa de fidelización por parte de los afiliados, NAOS necesita recopilar y tratar sus datos de carácter personal con el fin de conocer mejor a sus clientes y poder así fidelizarlos. Así, la negativa a proporcionarnos aquellos datos personales que resulten necesarios para ejecutar las condiciones generales de adhesión al programa de fidelización NAOS CLUB o realizar tratamientos de datos inherentes al mismo por parte de un Interesado, implicará la imposibilidad del citado Interesado pueda formar parte del mismo ya que no sería posible en tal caso ejecutar las condiciones generales de adhesión y que el Interesado pudiera beneficiarse de la finalidad de NAOS CLUB.
          </p>
          <p class="margen">
              <b>El cumplimiento de una obligación legal</b>: NAOS como empresa, actúa como responsable de los datos personales de los Interesados y es por ello por lo que debe cumplir con una serie de obligaciones impuestas por normativa contable, fiscal, de consumo, prevención de delitos, etc. En aquellos casos en que la justificación legal del tratamiento de los datos personales sea el cumplimiento de una obligación legal, los Interesados no podrán oponerse a su tratamiento.
          </p>
          <p class="margen">
              <b>Tu consentimiento</b>: En el caso de que NAOS desee realizar algún tratamiento de sus datos cuya justificación legal no pueda basarse en ninguno de los motivos anteriormente explicados, solicitaremos previamente tu consentimiento para dicho tratamiento. En todo caso, te informaremos debidamente con carácter previo de todos los aspectos derivados de dicho tratamiento y siempre podrás retirar tu consentimiento una vez lo hayas proporcionado. Es el caso, por ejemplo, de la conservación y publicación de valoraciones de productos NAOS.
          </p>
          <p class="negrita">
              ¿Durante cuánto tiempo conservamos tus datos personales?
          </p>
          <p>
              En esta columna se informa de manera orientativa durante cuánto tiempo se conservarán tus datos. El tiempo de conservación dependerá en todo caso del tratamiento que se lleve a cabo sobre tu información personal. Debes tener en cuenta que determinada normativa contable, fiscal, de consumo y protección de datos nos puede obligar a conservar determinados datos de los Interesados durante un tiempo determinado.
          </p>
          <p class="negrita">
              ¿Cuáles son tus derechos?
          </p>
          <p>
              Esta columna describe los derechos que puedes ejercer sobre tus datos, atendiendo al tratamiento que se realice sobre los mismos. Tal y como explicábamos en el punto 5 de la presente política de privacidad, deberás tener en cuenta que dependiendo de la base legal que legitime el tratamiento de tus datos, tus derechos pueden verse limitados.
          </p>
          <p>
              A continuación, encontrarás descritos con mayor detalle los distintos tratamientos que NAOS realiza de tus datos personales:
          </p>
      </div>
  </div>
  <br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              1. Gestión de la adhesión del afiliado al programa de fidelización NAOS CLUB
          </p>
          <table class="table table-bordered" style="text-align: center">
              <thead>
                  <tr style="color: white; background-color: #637691">
                      <th style="width: 300px">¿Para qué finalidades tratamos tus datos?</th>
                      <th>¿Sobre qué base legal tratamos tus datos?</th>
                      <th>¿Durante cuánto tiempo conservamos tus datos personales?</th>
                      <th>¿Cuáles son tus derechos y cómo puedes ejercerlos?</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>
                          <ul>
                              <li>Tramitar la afiliación de los clientes al programa de fidelización NAOS CLUB.</li>
                              <li>Gestionar la participación de los afiliados en las promociones exclusivas derivadas de la afiliación al programa de fidelización NAOS CLUB (sorteos, concursos, eventos, etc.).</li>
                              <li>Aplicar a los afiliados las ventajas exclusivas derivadas de la afiliación al programa de fidelización NAOS CLUB (acumulación de Estrellas NAOS, descuentos, ofertas, promociones, etc.).</li>
                              <li>Gestionar reclamaciones, dudas y consultas de los afiliados.</li>
                          </ul>
                      </td>
                      <td>
                          Ejecución de las condiciones generales de afiliación al programa de fidelización NAOS CLUB.
                      </td>
                      <td>
                          Los datos personales serán tratados por NAOS mientras esté vigente la relación de afiliación a NAOS CLUB existente entre NAOS y el afiliado.
                          Posteriormente, los datos personales proporcionados se conservarán debidamente bloqueados durante el plazo legalmente estipulado de prescripción de las acciones que puedan derivarse como consecuencia de la relación existente entre NAOS y el afiliado. En concreto, 5 años en materia de reclamación civil y de consumo. Alcanzado el plazo máximo de conservación de los datos, NAOS anonimizará o eliminará los datos personales del afiliado.
                      </td>
                      <td>
                          Podrás ejercer los derechos descritos en el apartado 5 de la presente política de privacidad, según lo estipulado en dicho apartado.
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              2. Análisis, segmentación y perfilado de los datos de los Interesados
          </p>
          <table class="table table-bordered" style="text-align: center">
              <thead>
                  <tr style="color: white; background-color: #637691">
                      <th style="width: 300px">¿Para qué finalidades tratamos tus datos?</th>
                      <th>¿Sobre qué base legal tratamos tus datos?</th>
                      <th>¿Durante cuánto tiempo conservamos tus datos personales?</th>
                      <th>¿Cuáles son tus derechos y cómo puedes ejercerlos?</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>
                          <ul>
                              <li>Analizar y segmentar las características, datos, gustos, intereses, preferencias, historial de compra de los productos y patrones de uso o aprovechamiento de las ventajas del programa de fidelización por parte de los afiliados con el fin de obtener perfiles comunes de afiliados.</li>
                              <li>Enriquecer los perfiles de los afiliados con datos obtenidos de los mismos de otras fuentes o canales de comunicación con NAOS (reclamaciones, formulario de contacto, contacto en rrss, etc.).</li>
                              <li>Diseñar promociones y ventajas exclusivas (eventos, sorteos, concursos, descuentos, ofertas, etc.) y comunicaciones comerciales y publicitarias para su envío por vías electrónicas (newsletters, sms, etc.) de forma personalizada en base a los resultados de las acciones de análisis, segmentación y perfilado.</li>
                              <li>Recomendar a los afiliados de forma personalizada los productos de las marcas BIODERMA e INSTITUT ESTHEDERM más adecuados según los resultados de las acciones de análisis, segmentación y perfilado.</li>
                          </ul>
                      </td>
                      <td>
                          Ejecución de las condiciones generales de afiliación al programa de fidelización NAOS CLUB.
                      </td>
                      <td>
                          Los datos personales serán tratados por NAOS mientras esté vigente la relación de afiliación a NAOS CLUB existente entre NAOS y el afiliado.
                          Posteriormente, los datos personales proporcionados se conservarán debidamente bloqueados durante el plazo legalmente estipulado de prescripción de las acciones que puedan derivarse como consecuencia de la relación existente entre NAOS y el afiliado. En concreto, 5 años en materia de reclamación civil y de consumo. Alcanzado el plazo máximo de conservación de los datos, NAOS anonimizará o eliminará los datos personales del afiliado.


                      </td>
                      <td>
                          Podrás ejercer los derechos descritos en el apartado 5 de la presente política de privacidad, según lo estipulado en dicho apartado.
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              2.1. ¿Qué es la segmentación y perfilado?
          </p>
          <p>
              Con el fin de diseñar, ofrecer y recomendar a los Interesados eventos, ofertas, promociones, productos y servicios lo más personalizadas posibles según sus intereses, características e historial de aprovechamiento de NAOS CLUB, asistencia o participación y poder así fidelizarlos como clientes mediante un servicio y atención más personalizado utilizamos técnicas de segmentación, analítica y perfilado para conocer mejor cuáles son sus características, intereses, necesidades y preferencias.
          </p>
          <p>
              La segmentación y el perfilado son técnicas y procesos automatizados cuya finalidad es mejorar la calidad de los datos de los Interesados de que disponemos. La aplicación de técnicas de perfilado sobre los datos de los los Interesados nos permite descubrir y definir requisitos y reglas de calidad sobre dichos datos y las técnicas de segmentación nos permiten segmentar los Interesados en base a los distintos requisitos y reglas obtenidos con las técnicas de perfilado. Así, podemos identificar unos perfiles comunes de los Interesados a los que podemos diseñar, ofrecer y recomendar promociones, productos, servicios e información sobre acciones promocionales y ventajas de forma mucho más personalizada y adecuada según sus características, intereses, necesidades y preferencias.
          </p>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              2.2. ¿Qué información captamos para llevar a cabo el perfilado?
          </p>
          <p>
              Para llevar a cabo el perfilado necesitamos captar y tratar la información relacionada en el punto 3 de la presente política de privacidad, entre ella, tus datos básicos (domicilio, edad, nombre y dirección completos), tus características, preferencias, gustos, hábitos, temas de interés, historial de participación en acciones promocionales o eventos de la compañía, historial de compra, tus perfiles de redes sociales y tu interacción con nosotros a través de nuestro perfiles en redes sociales (en los casos en que consientas en compartir esta información personal con nosotros), información sobre tu tipo de piel, patrón de uso y aprovechamiento del club de fidelización, opiniones sobre nuestros productos y servicios, etc.

          </p>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              2.3. ¿Cómo afecta el perfilado al envío de comunicaciones comerciales y promociones por vía electrónica?
          </p>
          <p>
              Los perfiles obtenidos son utilizados para ofrecer a los Interesados, productos, servicios, recomendaciones, eventos, cursos y proyectos de formación, así como comunicaciones comerciales personalizadas, acordes con tus características, preferencias, intereses e historial de contratación y/o participación. Así, evitamos ofrecer productos y servicios que no se adecúan a las necesidades de los Interesados o enviarles promociones o comunicaciones comerciales que no sean de su interés. El perfilado del conjunto de sus datos personales nos permite en definitiva conocer mejor a los Interesados y darles un servicio de mayor calidad.
          </p>
          <p>
              Como resultado, mediante la segmentación y el perfilado podemos agrupar a los Interesados según coincidan o se diferencien en distintas reglas como, por ejemplo, los productos que les interesan, las promociones a las que más se acogen, sus hábitos de compra y participación en acciones promocionales, necesidades comunes o su respuesta a las comunicaciones comerciales enviadas por NAOS.
          </p>
      </div>
  </div>
  <br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              3. Envío de comunicaciones comerciales y promocionales por vías electrónicas
          </p>
          <table class="table table-bordered" style="text-align: center">
              <thead>
                  <tr style="color: white; background-color: #637691">
                      <th style="width: 300px">¿Para qué finalidades tratamos tus datos?</th>
                      <th>¿Sobre qué base legal tratamos tus datos?</th>
                      <th>¿Durante cuánto tiempo conservamos tus datos personales?</th>
                      <th>¿Cuáles son tus derechos y cómo puedes ejercerlos?</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>
                          <ul>
                              <li>Enviar comunicaciones comerciales y con fines promocionales sobre productos, servicios, novedades, eventos exclusivos y demás información de NAOS por vías electrónicas (sms, email, etc.) personalizadas en base a los resultados del análisis, segmentación y perfilado de los afiliados. </li>
                          </ul>
                      </td>
                      <td>
                          La base legal para el tratamiento de tus datos son el consentimiento de los Interesados cuando no resulte aplicable el interés legítimo de NAOS de mantener una comunicación directa regular con los Interesados con fines de mercadotecnia.
                      </td>
                      <td>
                          Tus datos personales serán tratados por NAOS para esta finalidad hasta que ejerzas tu derecho de oposición o supresión.
                          En el momento en que retires tu consentimiento al uso de tus datos personales para el envío de newsletters, los datos personales proporcionados se conservarán debidamente bloqueados durante el plazo legalmente estipulado de prescripción de las acciones que puedan derivarse del tratamiento. En concreto, 3 años en materia de en materia de protección de datos personales.
                          Alcanzado el plazo máximo de conservación de los datos, NAOS anonimizará o eliminará los datos personales del Afiliado.

                      </td>
                      <td>
                          Podrás oponerte a recibir comunicaciones comerciales en cualquier momento a través de las siguientes vías:
                          <ul>
                              <li>revocando tu consentimiento en cualquier momento a través del envío de un correo electrónico solicitándolo a: <a href="mailTo:bajaemails@gruponaos.es">bajaemails@gruponaos.es</a></li>
                              <li>siguiendo las instrucciones en el pie del cuerpo de cada una de las comunicaciones electrónicas que recibas.</li>
                          </ul>
                          Asimismo, podrás ejercer los demás derechos descritos en el punto 5 de la presente política de privacidad, según lo estipulado en los mismos.
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              4. Lectura de los productos BIODERMA/INSTITUT ESTHEDERM en los tickets de compra
          </p>
          <table class="table table-bordered" style="text-align: center">
              <thead>
                  <tr style="color: white; background-color: #637691">
                      <th style="width: 300px">¿Para qué finalidades tratamos tus datos?</th>
                      <th>¿Sobre qué base legal tratamos tus datos?</th>
                      <th>¿Durante cuánto tiempo conservamos tus datos personales?</th>
                      <th>¿Cuáles son tus derechos y cómo puedes ejercerlos?</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>
                          <ul>
                              <li>Registrar los productos BIODERMA y/o INSTITUT ESTHEDERM efectivamente adquiridos por el afiliado según el ticket de compra y asignar las estrellas correspondientes conforme al programa de puntos de NAOS CLUB.</li>
                          </ul>
                      </td>
                      <td>
                          Ejecución de las condiciones generales de afiliación al programa de fidelización NAOS CLUB.
                      </td>
                      <td>
                          La lectura en el ticket de compra del nombre de Afiliado, los nombres de los productos adquiridos por este último y el punto de venta en el que se ha realizado la compra es un procedimiento técnico de naturaleza instantánea. Esto quiere decir que dicha lectura se hace de forma instantánea en cuanto el Afiliado sube el ticket de compra a su cuenta en NAOS CLUB, durando unas fracciones de segundo.
                      </td>
                      <td>
                          Podrás ejercer los derechos descritos en el apartado 5 de la presente política de privacidad, según lo estipulado en dicho apartado.
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              4.1. ¿Por qué es necesaria la lectura de los productos BIODERMA/INSTITUT ESTHEDERM en los tickets de compra?
          </p>
          <p>
              Los afiliados acumulan Estrellas NAOS en base a los productos de las marcas BIODERMA e INSTITUT ESTHEDERM adquiridos en Puntos de Venta adheridos a NAOS CLUB. Las Estrellas NAOS acumuladas en cada caso variará dependiendo del tipo de Punto de venta donde el afiliado haya adquirido los productos BIODERMA e INSTITUT ESTHEDERM.
          </p>
          <p>
              Para saber los productos de las marcas BIODERMA e INSTITUT ESTHEDERM adquiridos por el afiliado e identificar si se han adquirido en un Punto de Venta de Origen o en un Punto de Venta Ordinario para, así, poder registrar dichos productos y acumular en el saldo del afiliado las Estrellas NAOS correspondientes (dando así cumplimiento a una de las finalidades de NAOS CLUB), NAOS necesita acceder a los tickets de compra ya que recogen dicha información necesaria.
          </p>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              4.2. ¿Cómo se realiza la lectura de los productos BIODERMA/INSTITUT ESTHEDERM en los tickets de compra?
          </p>
          <p>
              Con el fin de respetar la privacidad de los afiliados y no acceder a otra información que puedan contener los tickets de compra distinta de la información relativa a los productos de las marcas BIODERMA e INSTITUT ESTHEDERM, NAOS ha decidido implementar una herramienta que permite digitalizar los tickets de compra y extraer solo la información que le interesa a los efectos de cumplir con las condiciones de afiliación de NAOS CLUB mediante un sistema de reconocimiento óptico de caracteres.
          </p>
          <p>
              Mediante el sistema de reconocimiento óptico de caracteres los tickets de compra son digitalizados extrayendo de los mismos la siguiente información, que es la que mínimamente necesita NAOS para poder cumplir con las condiciones de afiliación de NAOS CLUB y acumular  en el saldo del afiliado las Estrellas NAOS correspondientes a su compra: Nombre y CIF del punto de venta donde se ha realizado la compra, productos de las marcas BIODERMA e INSTITUT ESTHEDERM adquiridos, importe y número de unidades y número y fecha de ticket. La digitalización de los tickets se consigue mediante un proceso inverso de búsqueda inteligente de la información del ticket.
          </p>
          <p>
              Una vez finalizado el proceso de digitalización de los tickets, se valida la información contenida en los mismos contrastándola con las bases de datos de los Puntos de Venta adheridos a NAOS CLUB y de los productos de las marcas BIODERMA e INSTITUT ESTHEDERM y, si la información obtenida cumple con los requisitos establecidos en las condiciones de adhesión de NAOS CLUB, se proceden a acumular las Estrellas NAOS correspondientes en la cuenta de usuario del afiliado.
          </p>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              4.3. ¿Cómo afecta la lectura del ticket de compra a tu privacidad?
          </p>
          <p>
              El sistema de reconocimiento óptico de caracteres empleado para leer los tickets de compra está diseñado para extraer única y exclusivamente la información anteriormente citada, sin que en ningún caso se trate ninguna otra información que puedan contener los tickets de compra. NAOS ha optado por esta opción por considerarla la más respetuosa con la privacidad de los afiliados y la menos intrusiva para con la información que pudiera tener el ticket de compra.
          </p>
          <p>
              Por otro lado, la asignación de las Estrellas NAOS correspondientes a los productos adquiridos por el Afiliado según la lectura de los tickets de compra anteriormente explicado, es una decisión basada únicamente en un tratamiento automatizado, es decir, es un proceso plenamente automatizado en el que no hay participación humana y como resultado del cual se decide cuántas Estrellas NAOS asignar al afiliado en su cuenta de usuario. Aunque este proceso no produce en los afiliados efectos jurídicos (denegación de derechos, cancelaciones contractuales, imposición de gastos, etc.), ni efectos de otro tipo que les puedan afectar significativamente de modo similar (efectos en las circunstancias de los afiliados, impacto prolongado en el tiempo en la privacidad de los mismos o su discriminación, por ejemplo), por prudencia NAOS ha incluido un proceso de reclamación de resultados de lecturas de tickets de compra para evitar cualquier inconveniente o perjuicio a los afiliados.
          </p>
      </div>
  </div>
  <br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              5. Almacenamiento temporal de los tickets de compra de productos BIODERMA/INSTITUT ESTHEDERM
          </p>
          <table class="table table-bordered" style="text-align: center">
              <thead>
                  <tr style="color: white; background-color: #637691">
                      <th style="width: 300px">¿Para qué finalidades tratamos tus datos?</th>
                      <th>¿Sobre qué base legal tratamos tus datos?</th>
                      <th>¿Durante cuánto tiempo conservamos tus datos personales?</th>
                      <th>¿Cuáles son tus derechos y cómo puedes ejercerlos?</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>
                          <ul>
                              <li>Disponer del tickets de compras subidos por los afiliados poder tramitar las reclamaciones de los afiliados en los casos en que el proceso de lectura del ticket de compra no sea posible, contrastando así el contenido del ticket de compra subido por el afiliado con el proceso de lectura del mismo realizado por NAOS.</li>
                          </ul>
                      </td>
                      <td>
                          Ejecución de las condiciones generales de afiliación al programa de fidelización NAOS CLUB.
                      </td>
                      <td>
                          Los tickets de compra de los afiliados serán conservados por NAOS durante un plazo máximo de catorce (14) días naturales desde la fecha de registro de los productos BIODERMA y/o INSTITUT ESTHEDERM contenidos en el mismo en la cuenta de usuario del afiliado.
                          Alcanzado el plazo máximo de conservación de los datos, NAOS anonimizará o eliminará los datos personales del afiliado.
                      </td>
                      <td>
                          Los afiliados podrán ejercer los demás derechos descritos en el punto 5 de la presente política de privacidad, según lo estipulado en los mismos.
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              5.1. ¿Por qué es necesario conservar temporalmente los tickets de compra?
          </p>
          <p>
              La herramienta de digitalización de los tickets de compra y extracción de la información relevante mediante un sistema de reconocimiento óptico de caracteres no es infalible. Además, es posible que el estado físico del propio ticket de compra o las condiciones en las que se saque su fotografía hagan imposible su lectura y por tanto, no sea posible registrar los productos de las marcas BIODERMA e INSTITUT ESTHEDERM adquiridos por el afiliado e identificar si se han adquirido en un Punto de Venta de Origen o en un Punto de Venta Ordinario sin que se puedan, en tal caso, acumular en el saldo del afiliado las Estrellas NAOS correspondientes.
          </p>
          <p>
              Para los casos en que no sea posible leer el ticket de compra y el afiliado interponga la correspondiente reclamación, NAOS debe disponer del ticket de compra en cuestión para realizar las comprobaciones oportunas y poder tramitar así la reclamación planteada por el afiliado, acumulando, en su caos, las Estrellas NAOS correspondientes en el saldo del afiliado.
          </p>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              5.2. ¿Cómo se almacenan temporalmente los tickets de compra?
          </p>
          <p>
              La tecnología seleccionada para almacenar los tickets durante el plazo de reclamación se ha elegido precisamente para garantizar que dicha información se almacena de forma segura y confidencial y que únicamente puede acceder a dicha información el personal competente para realizar las comprobaciones oportunas para tramitar las reclamaciones que el Afiliado pueda presentar en los casos en que el proceso de lectura del ticket de compra no sea posible. NAOS no accederá, ni tratará los tickets de compra en ningún otro caso, ni para ninguna otra finalidad. Una vez finalizado el plazo para interponer reclamaciones por parte de los afiliados, NAOS eliminará de forma automática, definitiva e irreversible los tickets de compra.
          </p>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              5.3. ¿Cómo afecta el almacenamiento del ticket de compra a tu privacidad?
          </p>
          <p>
              Dado que los tickets de compra pueden contener más información además de la información relativa a los productos de las marcas BIODERMA e INSTITUT ESTHEDERM y ello puede reflejar características o información sobre los afiliados, NAOS ha decidido limitar al máximo el plazo de su conservación, limitándolo al plazo de reclamación. Una vez finalizado el Plazo de Reclamación, NAOS eliminará de forma automática, definitiva e irreversible los tickets de compra.
          </p>
      </div>
  </div>
  <br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              6. Conservación y publicación de valoraciones de productos
          </p>
          <table class="table table-bordered" style="text-align: center">
              <thead>
                  <tr style="color: white; background-color: #637691">
                      <th style="width: 300px">¿Para qué finalidades tratamos tus datos?</th>
                      <th>¿Sobre qué base legal tratamos tus datos?</th>
                      <th>¿Durante cuánto tiempo conservamos tus datos personales?</th>
                      <th>¿Cuáles son tus derechos y cómo puedes ejercerlos?</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>
                          <ul>
                              <li>Publicar las valoraciones de los afiliados de los productos de las marcas BIODERMA e INSTITUT ESTHEDERM en las páginas webs corporativas de BIODERMA e INSTITUT ESTHEDERM.</li>
                              <li>Analizar y estudiar las valoraciones de los afiliados de los productos de las marcas BIODERMA e INSTITUT ESTHEDERM para mejorar.</li>
                          </ul>
                      </td>
                      <td>
                          Consentimiento del Afiliado.
                      </td>
                      <td>
                          Los datos personales serán tratados por NAOS mientras esté vigente la relación de afiliación a NAOS CLUB existente entre NAOS y el afiliado o hasta que el afiliado retire su consentimiento a dicho tratamiento.
                          Posteriormente, los datos personales proporcionados se conservarán debidamente bloqueados durante el plazo legalmente estipulado de prescripción de las acciones que puedan derivarse como consecuencia de la relación existente entre NAOS y el afiliado. En concreto, 5 años en materia de reclamación civil y de consumo.
                          Alcanzado el plazo máximo de conservación de los datos, NAOS anonimizará o eliminará los datos personales del afiliado.

                      </td>
                      <td>
                          Los afiliados podrán ejercer los demás derechos descritos en el punto 5 de la presente política de privacidad, según lo estipulado en los mismos.
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              7. Uso de herramientas de diagnóstico de la piel
          </p>
          <table class="table table-bordered" style="text-align: center">
              <thead>
                  <tr style="color: white; background-color: #637691">
                      <th style="width: 300px">¿Para qué finalidades tratamos tus datos?</th>
                      <th>¿Sobre qué base legal tratamos tus datos?</th>
                      <th>¿Durante cuánto tiempo conservamos tus datos personales?</th>
                      <th>¿Cuáles son tus derechos y cómo puedes ejercerlos?</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>
                          <ul>
                              <li>Realizar un análisis de las características de la piel de los Afiliados y, en base a los resultados obtenidos del mismo, recomendarles productos de las marcas BIODERMA/INSTITUT ESTHEDERM.</li>
                              <li>Gestionar cualquier duda o incidencia de los Afiliados en relación con el diagnóstico de la piel realizado. </li>
                          </ul>
                      </td>
                      <td>
                          Consentimiento del Afiliado.
                      </td>
                      <td>
                          Los datos personales serán tratados por NAOS mientras esté vigente la relación de afiliación a NAOS CLUB existente entre NAOS y el afiliado.
                          Posteriormente, los datos personales proporcionados se conservarán debidamente bloqueados durante el plazo legalmente estipulado de prescripción de las acciones que puedan derivarse como consecuencia de la relación existente entre NAOS y el afiliado.  En concreto, 5 años en materia de reclamación civil y de consumo.
                          Alcanzado el plazo máximo de conservación de los datos, NAOS anonimizará o eliminará los datos personales del afiliado.
                      </td>
                      <td>
                          Podrás ejercer los derechos descritos en el apartado 5 de la presente política de privacidad, según lo estipulado en dicho apartado.
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              8. Mantenimiento de listas de supresión actualizadas para evitar ser contactado si así lo solicita el Interesado.
          </p>
          <table class="table table-bordered" style="text-align: center">
              <thead>
                  <tr style="color: white; background-color: #637691">
                      <th style="width: 300px">¿Para qué finalidades tratamos tus datos?</th>
                      <th>¿Sobre qué base legal tratamos tus datos?</th>
                      <th>¿Durante cuánto tiempo conservamos tus datos personales?</th>
                      <th>¿Cuáles son tus derechos y cómo puedes ejercerlos?</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>
                          <ul>
                              <li>Mantener en una lista de supresión con la confirmación de aquellos Interesados que hayan solicitado la supresión de sus datos o su oposición al tratamiento de los mismos.</li>
                          </ul>
                      </td>
                      <td>
                          Obligación legal: el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE nos obliga a respetar sus derechos y mantener evidencia del cumplimiento por nuestra parte de tu petición de ejercicio del derecho de supresión.
                      </td>
                      <td>
                          Conservaremos los datos personales de los afiliados durante el plazo legalmente previsto y, posteriormente, durante el plazo de prescripción para la interposición de cualquier reclamación en relación con la efectiva o deficiente respuesta por nuestra parte al derecho de supresión u oposición que hayan ejercitado. En concreto, 3 años en materia de protección de datos personales.
                          Alcanzado el plazo máximo de conservación de los datos, NAOS anonimizará o eliminará los datos personales del Afiliado.
                      </td>
                      <td>
                          Los derechos descritos en el apartado 5 de la presente política de privacidad quedan limitados por ser este un tratamiento fundamentado en una obligación legal, tal y como se explica en el citado apartado.
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              7. ¿A QUIÉN COMUNICAMOS TUS DATOS?
          </p>
          <p>
              1. NAOS podrá comunicar los datos de carácter personal de los Interesados a empresas del mismo grupo societario, puntos de venta (establecimientos que comercializan productos de las marcas titularidad de NAOS, tales como farmacias, tiendas de parafarmacia, centros de estética, etc.) o cualesquiera otros terceros relevantes necesarios para tramitar, gestionar o resolver cualquier incidencia, consulta o petición relacionada con la afiliación a NAOS CLUB, cuando ello sea necesario como, por ejemplo, empresas responsables de los recintos donde tengan lugar los eventos organizados por NAOS a propósito de NAOS CLUB con el fin de verificar la lista de asistentes y comprobar el aforo a dichos recintos.
          </p>
          <p>
              2. Otros posibles terceros a los que podemos comunicar tus datos, previo requerimiento legal o normativa aplicable que legitime la comunicación son:
          </p>
          <ul>
              <li>Asesores legales</li>
              <li>Juzgados y Tribunales</li>
              <li>Administración Tributaria</li>
              <li>Agencias Autonómicas y Entidades de consumo</li>
              <li>Organismos de gobierno y administración pública </li>
              <li>Fuerzas y cuerpos de seguridad.</li>
          </ul>
          <p>
              3. Asimismo, podremos comunicar tu información personal en los siguientes casos:
          </p>
          <ul>
              <li>Si nosotros o sustancialmente todos nuestros activos, son adquiridos por un tercero en cuyo caso, los datos de carácter personal que tenemos de los Interesados serán uno de los activos transferidos. Cualquier operación de este tipo será debidamente informada y comunicada.</li>
              <li>Para proteger la seguridad de los derechos de nuestro personal, los Interesados y nuestros sistemas de información y defender los intereses de la propia compañía. Ello incluye intercambiar información con fuerzas y cuerpos de seguridad o Tribunales en los casos de detección de fraude, usurpación de identidad o cualquier otro uso, tratamiento o hecho ilícito.</li>
          </ul>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              8. ¿QUIÉN PUEDE ACCEDER A TUS DATOS?
          </p>
          <p>
              NAOS informa a los Interesados que trabajamos con terceros proveedores cuyos servicios son necesarios para la correcta funcionalidad y seguridad de nuestros sistemas de información y que, por razón del ejercicio de su actividad, pueden tener acceso a tus datos. Es el caso, por ejemplo, de los proveedores que nos ayudan a mejorar y optimizar nuestras páginas webs corporativas, los proveedores de servicios tecnológicos de soluciones para leer los tickets de compra de forma automática o los proveedores de servicios de mantenimiento y comunicación de nuestras bases de datos y sistemas de información y de almacenamiento de datos. También podrán acceder a los datos de los Interesados empresas de mensajería y transportes cuando ello sea necesario para hacer entrega de los correspondientes premios en el caso de participación en una de nuestras acciones promocionales.
          </p>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              9. ¿TRANSFERIMOS TUS DATOS PERSONALES FUERA DEL ESPACIO ECONÓMICO EUROPEO?
          </p>
          <p>
              El personal que presta el servicio de atención al usuario se encuentra ubicado en Colombia, país que esta fuera del Espacio Económico Europeo. Con el fin de garantizar el cumplimiento de la normativa europea vigente en cada momento en materia de protección de datos personales, NAOS ha firmado con el proveedor de servicio de atención al usuario las correspondientes cláusulas contractuales tipo para la transferencia de datos personales a los encargados del tratamiento establecidos en terceros países, aprobadas de conformidad con la Directiva 95/46/CE del Parlamento Europeo y del Consejo por la Decisión de la Comisión de 5 de febrero de 2010, tal y como establece el artículo 46 del RGPD.
          </p>
          <p>
              Puedes ver el modelo oficial de dichas cláusulas tipo aprobado por la Comisión Europea en el apartado que la Agencia Española de Protección de Datos tiene en su web a propósito de las transferencias internacionales: <a href="https://www.aepd.es/es/documento/2010d0087-es-1.pdf">https://www.aepd.es/es/documento/2010d0087-es-1.pdf</a>. También puedes conocer en detalle qué son estas cláusulas tipo en el apartado de la web de la Agencia Española de Protección de Datos relativo a transferencias internaciones: <a href="https://www.aepd.es/es/derechos-y-deberes/cumple-tus-deberes/medidas-de-cumplimiento/transferencias-internacionales">https://www.aepd.es/es/derechos-y-deberes/cumple-tus-deberes/medidas-de-cumplimiento/transferencias-internacionales</a>. Si tienes cualquier duda respecto a la seguridad y confidencialidad de tus datos a este respecto, puedes contactar con nuestro Delegado de Protección de Datos (DPO) a través del email <a href="mailto:protecciondatos@es.naos.com">protecciondatos@es.naos.com</a>.
          </p>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              10. ¿ESTÁN TUS DATOS SEGUROS?
          </p>
          <p>
              Con el fin de asegurar un procesamiento justo y transparente de tu información personal, adoptaremos los procedimientos adecuados que incluirán la implementación de medidas técnicas y organizativas que tengan en cuenta el posible riesgo y corrijan cualquier imprecisión identificada en los datos personales tratados, de modo que el riesgo de cualquier error se minimice, tratando tus datos de manera justa y segura.
          </p>
          <p>
              Toda la información que nos proporciones se almacenará en servidores seguros. Así, una vez hayamos recibido toda tu información utilizaremos procedimientos estrictos de seguridad para tratar de evitar cualquier acceso no autorizado. Lamentablemente, la transmisión o comunicación de información a través de internet no es complemente segura.
          </p>
          <p>
              Igualmente, nos aseguramos de que nuestros proveedores de servicios también gozan de estándares de seguridad adecuados para la protección de los datos de carácter personal respecto de los cuales tengan o puedan llegar a tener acceso, en atención con la legislación de protección de datos aplicable vigente en cada momento.
          </p>
      </div>
  </div><br>
  <div class="row">
      <div class="col-md-12">
          <p class="negrita">
              11. CAMBIOS EN LA PRESENTE POLÍTICA DE PRIVACIDAD
          </p>
          <p>
              La presente Política de Privacidad podrá variar con el tiempo debido a los posibles cambios de criterio seguidos en cada momento por la Agencia Española de Protección de Datos u otras autoridades de control, las modificaciones legislativas que puedan tener lugar y los pronunciamientos jurisprudenciales que resulten de aplicación a la presente política de privacidad. NAOS se reserva por tanto el derecho a modificar la presente Política de Privacidad para poder adaptarla a dichos criterios, así como a novedades jurisprudenciales o legislativas.
          </p>
      </div>
  </div>
  <div class="row">
      <div class="col-md-12">
          Fecha de inicio de la presente versión: 17 de mayo del 2021.
      </div>
  </div><br>
  <div class="row" style="text-align: center">
      <div class="col-md-12">
          <button class="btn btnSendTicket" (click)="this.goToAfter()">
              Volver
          </button>
      </div>
  </div>
</div>
