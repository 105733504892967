import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from './services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NAOSService } from './services/naos.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppSettings } from './constants.component';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { LangService } from './services/lang.service';
import { TarteaucitronService } from './services/tarteaucitron.service';
import { Popover } from 'bootstrap';

declare var jQuery: any;
declare var $: any;
declare var tarteaucitron: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [LoginService, NAOSService]
})
export class AppComponent implements OnInit {

  /* title = 'naos-loyalty'; */
  public redirect: string;
  public brand: string;
  public sub: any;

  public user: string;
  public password: string;

  public network: any;
  public token: any;
  public idpromotions: any;
  public favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  public initbrand: string;

  @ViewChild('modalMaintenance', {static: true}) modalMaintenance;
  public modal: NgbModalRef;

  constructor(
    private _router: Router,
    private _loginService: LoginService,
    private _naosService: NAOSService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private title: Title,
    private meta: Meta,
    private langService: LangService,
    private tarteaucitronService: TarteaucitronService
  ) {
    this.redirect = undefined;
    this.brand = undefined;
    /* localStorage.setItem('init', 'bioderma'); */

    if (localStorage.getItem('init')) {
      this.initbrand = localStorage.getItem('init');
      if (this.initbrand !== 'bioderma') {
        // cargamos los javascript de esthederm si no hemos entrado por bioderma
        for (let jsFile of AppSettings.arrayJavaScriptEsthederm) {
         /*  var my_awesome_script = document.createElement('script');
          my_awesome_script.setAttribute('src',jsFile);
          document.head.appendChild(my_awesome_script); */
        }
      }
    }
  }

  ngOnInit(): void {
    
    Array.from(document.querySelectorAll('i[data-bs-toggle="popover"]'))
    .forEach(popoverNode => new Popover(popoverNode));

    // cargamos el script de google maps
    const scriptGoogleMaps = document.createElement('script');
    scriptGoogleMaps.src = 'https://maps.googleapis.com/maps/api/js?key=' + environment.APIKEYMAPS + '&libraries=places';
    scriptGoogleMaps.type = 'text/javascript';
    scriptGoogleMaps.async = true;
    scriptGoogleMaps.defer = true;
    document.head.appendChild(scriptGoogleMaps);

    

    // Modificamos el title y el meta tag description según la marca cargada
    let urlPage: any = window.location.href;
    if (urlPage.indexOf('bioderma') > -1) {
      this.updateTitle('NAOS Club BIODERMA');
      this.updateDescription('NAOS Club. BIODERMA premia tus compras. Acumula puntos y canjéalos por productos gratis y eventos exclusivos.');
      // Añadimos el meta tag para index robots solo para producción
    if (environment.ENTORNOPROJECT == 'prod') {
      this.meta.addTags([
        { name: 'robots', content: 'index, follow' },
        { name: 'keywords', content: 'NAOS, naos, naos club, Bioderma, BIODERMA, bioderma' },
      ]);
    }
    } else if (urlPage.indexOf('esthederm') > -1) {
      this.updateTitle('NAOS Club – INSTITUT ESTHEDERM');
      this.updateDescription('NAOS Club – INSTITUT ESTHEDERM premia tus compras. Acumula puntos y canjéalos por productos gratis y eventos exclusivos.');
      // Añadimos el meta tag para index robots solo para producción
    if (environment.ENTORNOPROJECT == 'prod') {
      this.meta.addTags([
        { name: 'robots', content: 'index, follow' },
        { name: 'keywords', content: 'NAOS, naos, naos club, Esthederm, ESTHEDERM, esthederm' },
      ]);
    }
    } else {
      this.updateTitle('NAOS Club BIODERMA');
      this.updateDescription('NAOS Club. BIODERMA premia tus compras. Acumula puntos y canjéalos por productos gratis y eventos exclusivos.');
      // Añadimos el meta tag para index robots solo para producción
    if (environment.ENTORNOPROJECT == 'prod') {
      this.meta.addTags([
        { name: 'robots', content: 'index, follow' },
        { name: 'keywords', content: 'NAOS, naos, naos club, Bioderma, BIODERMA, bioderma' },
      ]);
    }
    }


    this._router.events.subscribe((event) => {
      window.scroll(0, 0);
    });
    this.route.queryParams.subscribe(params => {

      let intervaloToken = setInterval(() => {
        this.token = params['token'];
        if (this.token !== undefined) {
          localStorage.setItem('tkn', this.token);
          sessionStorage.setItem('NKT', this.token);
          clearInterval(intervaloToken);
        }
      }, 1000);

      let intervaloBrand = setInterval(() => {
        this.brand = params['brand'];
        if (this.brand !== undefined) {
          localStorage.setItem('brandweb', this.brand);
          clearInterval(intervaloBrand);
        }
      }, 1000);

      let intervaloPromotionLanding = setInterval(() => {
        this.idpromotions = params['promotion'];
        if (this.idpromotions !== undefined) {
          localStorage.setItem('landingpromotion', this.idpromotions);
          clearInterval(intervaloPromotionLanding);
        }
      }, 1000);

      let intervalRedirect = setInterval(() => {
        this.redirect = params['redirect'];
        if (this.redirect !== undefined && this.redirect !== null) {
          localStorage.setItem('redirectValue', this.redirect);
          this._naosService.redirectLinks(this.redirect);
          clearInterval(intervalRedirect);
        }
      }, 1000);
/*       if (this.redirect !== undefined) {
        localStorage.setItem('redirectValue', this.redirect);
      } */
    });

    localStorage.removeItem('reportInfo');

    /* setTimeout(() => {
      if (this.redirect !== undefined && this.redirect !== null) {
        this._naosService.redirectLinks(this.redirect);
      }
    }, 500); */

    this.loadIconClient();
  }

  ngAfterViewInit(): void {

    // Primero cargar Tarteaucitron.js
    /* this.tarteaucitronService.loadScript('https://cdnjs.cloudflare.com/ajax/libs/tarteaucitronjs/1.19.0/tarteaucitron.js')
      .then(() => {
        this.tarteaucitronService.initializeTarteaucitron();
      }).catch(error => {
        console.error('Error loading scripts:', error);
      }); */

      // Seleccionar el elemento del popover
    const popoverTriggerEl = document.getElementById('myPopover');

    // Verificar que el elemento existe
    if (popoverTriggerEl) {
      // Inicializar el popover
      new Popover(popoverTriggerEl);
    }
  }


  ngOnDestroy(): void {
   localStorage.removeItem('remember');
   localStorage.removeItem('redirectValue');
   localStorage.removeItem('tkn');
  }

  public updateTitle(title: string) {
    this.title.setTitle(title);
  }

  public updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

  public loadIconClient(): void {
    if (localStorage.getItem('init')) {
      if (localStorage.getItem('init') === 'bioderma') {
        setInterval(() => {
          /* this.favIcon.href = '../../../assets/images/LogowebBioderma.png';
          this.title.setTitle('NAOS Club'); */
          var link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          link.href = '../../../assets/images/LogowebBioderma1.png';
          document.getElementsByTagName('head')[0].appendChild(link);
        }, 1000);
      } else if (localStorage.getItem('init') === 'esthederm') {
        setInterval(() => {
          /* this.favIcon.href = '../../../assets/images/LogowebIE.png';
          this.title.setTitle('NAOS Club'); */
          var link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          link.href = '../../../assets/images/LogowebIE.png';
          document.getElementsByTagName('head')[0].appendChild(link);
        }, 1000);
      }

    } else {
      setTimeout(() => {
        /* this.favIcon.href = '../../../assets/images/ic_ppvv_naos.png';
        this.title.setTitle('NAOS Club'); */
        var link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = '../../../assets/images/LogowebBioderma1.png';
        document.getElementsByTagName('head')[0].appendChild(link);
      }, 1000);
    }
  }

  /**
   * Funcion para hacer el logueo de usuario de red
   */
  /* public loginRed(): void {
    if (this._loginService.loginRed(this.user, this.password)) {
      sessionStorage.setItem('networkUser', 'yes');
      if (localStorage.getItem('remember') == 'yes') {
        this._router.navigate(['/rememberpassword']);
      } else {
        if (localStorage.getItem('redirectValue') !== undefined) {
          this._naosService.redirectLinks(localStorage.getItem('redirectValue'));
        } else {
          this._router.navigate(['/main']);
        }
      }
      $('#modalRegisterRed').modal('hide');
    } else {
      this._router.navigate(['/noauthorization']);
      $('#modalRegisterRed').modal('hide');
    }
  } */

  public loadCssFile(): void {
    /* for (const item of this.refactor.css) {
        const head = document.getElementsByTagName("HEAD")[0];
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = item;
        head.appendChild(link);
    } */
    /* setTimeout(() => {
      this.loader = true;
    },1000); */

  }

  /**
   * Function to open popups
   * @param content popup
   */
  public openDialog(content) {
    if (this.modal) {
      this.modal.close();
    }
    this.modal = this.modalService.open(content, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }
}
