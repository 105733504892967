<div class="container mw-100">
    <div class="row">
        <div class="col-md-12 titlePage text-center mb-5">
            <b>PRODUCTOS DE TU CESTA</b>
        </div>
    </div>

    <div class="row loader" *ngIf="!this.contLoader">
        <div class="col-12 text-center">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>

    <div class="row justify-content-center" *ngIf="this.contLoader">
        <div class="col-lg-12 mb-5 mb-lg-0 row justify-content-center p-0" *ngIf="this.bag.length > 0">
            <!-- cards -->
            <div class="row bag-item-row p-3 my-2 justify-content-center" *ngFor="let item of this.bag;let i = index;">

                <!-- card deleted button -->
                <div class="col-md-auto lineaDeleteCard text-right iconDelete2-display p-0">
                    <i (click)="this.deleteProductBag(item.what_id)" class="fa fa-times-circle iconDelete" aria-hidden="true"></i>
                </div>

                <!-- card image -->
                <div class="col-8 col-md-4 text-center pt-4">
                    <img class=" w-100 h-auto" [src]="item.jwhat.product_info.multimedia.mainImage[1].url">
                </div>

                <!-- card body-->
                <div class="col-12 col-md-8 d-flex flex-column mt-3 mt-md-0 text-center">
                    <div class="row justify-content-end iconDelete-display">
                        <div class="col-md-auto lineaDeleteCard text-right">
                            <i (click)="this.deleteProductBag(item.what_id)" class="fa fa-times-circle iconDelete" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label class="lineaTitulo"> {{item.jwhat.product_info.item_sd.es}}</label>
                        </div>
                    </div>
                    <div class="row mt-4 justify-content-center">
                        <div class="col-sm-12 col-md-4 mb-5">
                            <label class="lineaDatos">Cantidad</label>
                        </div>
                        <div class="col-sm-12 col-md-6 row justify-content-center">
                            <input (change)="this.calcScore();this.setQuantityBagClient(i, item.quantity)" type="number"
                                min="1" [(ngModel)]="item.quantity" class="form-control inputQuantity inputRegister" id="cantidad">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-6 lineaDatos">
                            <label *ngIf="item.destiny == 'catalogo'">Formato {{ item.jwhat.attribute_desc.es}}</label>
                            <label *ngIf="item.destiny == 'detalle'">
                                <ng-container *ngIf="item.attributes !== undefined">
                                    {{ item.attributes[0].value.es}}
                                </ng-container>
                            </label>
                        </div>
                        <div class="col-sm-6 lineaDatos">
                            <img src="../../../assets/images/Group@2x.png" />
                            <label *ngIf="this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units) !== 0">
                                {{this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units) | number:'1.0-3':'de'}}
                                estrellas
                            </label>
                            <label *ngIf="this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units) == 0">
                                {{item.jwhat.product_info.item_sd.es}}
                            </label>
                        </div>
                    </div>
                    <div class="row mt-4 mb-2">
                        <div class="col-sm-6 lineaDatos"></div>
                        <div class="col-sm-6 lineaDatos">
                            ({{this.convertStarsToEuros(this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units))}} €)
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-12" *ngIf="this.bag.length == 0">
            <div class="alert alert-info">
                No tiene productos añadidos en su cesta.
            </div>
        </div>

        <div class="row col-lg-12 justify-content-center mt-4 CardResumen">

            <div class="col-sm-12 col-md-12 col-lg-4 mb-4 container bag-item-row2 px-5" *ngIf="this.bag.length > 0">
                <div class="row justify-content-center col-lg-12 mb-4">
                    <h2 class="blue-text text-center mt-4">Resumen</h2>
                </div>
                <div class="row lineaProductoCarrito py-4" *ngFor="let item of this.bag">
                    <div class="col-8">
                        <label>{{ item.jwhat.product_info.item_sd.es }}</label>
                    </div>
                    <div class="col-4 text-right">
                        <label *ngIf="this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units) !== 0">
                            {{ this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units) * item.quantity | number:'1.0-3':'de'}}</label>
                        <label *ngIf="this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units) == 0">0</label>
                    </div>
                </div>
                <div class="row py-5 blue-text">
                    <div class="col-8"> Total</div>
                    <div class="col-4 p-0 text-right" style="display: block ruby;">
                        {{this.scoreTotal | number:'1.0-3':'de'}}
                        <img src="../../../assets/images/Group.png" />
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4 container bag-item-row2" *ngIf="this.bag.length == 0">
                <div class="row justify-content-center col-lg-12 mb-4">
                    <h2 class="blue-text text-center mt-4">Resumen</h2>
                </div>
                <div class="row lineaProductoCarrito py-3" *ngFor="let item of this.bag">
                    <div class="col-8"><label></label></div>
                    <div class="col-4">
                        <label>0</label>
                    </div>
                </div>
                <div class="row lineaProductoCarritoCuadro py-3">
                    <div class="col-8"> Total de estrellas</div>
                    <div class="col-4 pl-0 text-right"><img src="../../../assets/images/Group.png" />
                        {{this.scoreTotal | number:'1.0-3':'de'}}</div>
                </div>
            </div>

            <ng-container *ngIf="this.showOptSendDelivery">
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4 container bag-item-row2 px-5">
                    <div class="row justify-content-center col-lg-12 mb-4">
                        <h2 class="blue-text text-center mt-4">Opciones de envío</h2>
                    </div>
                    <div class="row lineaProductoCarrito px-1 py-3" *ngIf="this.showReciby">
                        <div class="col-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" (click)="this.clickTypeCenter()"
                                    [(ngModel)]="this.typeSendCenter" value="1" id="centro">
                                <label class="form-check-label w-100" for="centro"> <span class="mr-1">Recoge tu pedido en
                                        tu farmacia o centro de estética.</span>
                                    <i style="font-size: 16px !important" [placement]="['right', 'bottom', 'auto']"
                                        ngbPopover="Te enviaremos por email un código que deberás presentar en el punto de venta para recoger el pedido antes de 30 días."
                                        class="fas fa-info-circle iconInfo ml-0"></i>
                                </label>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="this.emailCenter">
                        <div class="row lineaEnvio2 py-3">
                            <div class="col-12">
                                <div class="form-group row">
                                    <label for="email" class="col-sm-2 col-xs-12 col-md-12 col-form-label">Email</label>
                                    <div class="col-sm-10 col-xs-12 col-md-12">
                                        <input type="email" [(ngModel)]="this.emailValue" autocomplete="off"
                                            class="form-control" id="email">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row rowSitio py-3 justify-content-center">
                            <!-- {{this.idpharmacyAllowed}} - {{this.idsteticAllowed}} - {{this.showSendDirection}} -->
                            <div class="col-md-5 mx-1 row justify-content-center" *ngIf="this.idpharmacyAllowed" style="margin-top: 2%;">
                                <button class="btn btnSitio" id="buttonFarmacia" (click)="this.goToMap(1)">
                                    Farmacia favorita
                                </button>
                            </div>
                            <div class="col-md-5 mx-1 row justify-content-center" *ngIf="this.idsteticAllowed" style="margin-top: 2%;">
                                <button class="btn btnSitio" id="buttonEstetica" (click)="this.goToMap(2)">
                                    Centro de estética favorito
                                </button>
                            </div>
                            <div class="col-md-5 mx-1 row justify-content-center" style="margin-top: 2%;">
                                <button class="btn btnSitio" (click)="this.goToMap(3)">
                                    Seleccionar farmacia o centro de estética
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="this.bag.length > 0">
                        <ng-container *ngIf="this.bag[0].jwhat.delivery_type !== '2'">
                                <ng-container *ngIf="this.showSendDirection">
                                <div class="row lineaEnvio2 py-3" *ngIf="this.dataDirections.length > 0">
                                    <div class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" (click)="this.clickTypeDirection()"
                                                [(ngModel)]="this.typeSendDomicilio" value="1" id="domicilio">
                                            <label class="form-check-label" for="domicilio" style="width: 100%">
                                                <span class="mr-1">Envío a domicilio</span><i style="font-size: 16px !important"
                                                    [placement]="['right', 'bottom', 'auto']" ngbPopover="El plazo de envío será de 10 a 15 días."
                                                    class="fas fa-info-circle iconInfo ml-0"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row lineaEnvio2 py-3" *ngIf="this.direction">
                                    <div class="col-12">
                                        <fieldset class="form-group">
                                            <div class="form-group row">
                                                <label for="numberMobile"
                                                    class="col-sm-4 col-xs-12 col-md-12 col-form-label">Número teléfono</label>
                                                <div class="col-sm-6 col-xs-12 col-md-12">
                                                    <input type="text" (keyup)="this.setTelephoneResume()"
                                                        [(ngModel)]="this.numberMobile" autocomplete="off" class="form-control"
                                                        id="numberMobile">
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group">
                                            <div class="row" *ngIf="this.dataDirections.length > 0">
                                                <div class="col-sm-10">
                                                    <div class="form-check"
                                                        *ngFor="let direction of this.dataDirections;let i = index;">
                                                        <input class="form-check-input" type="radio" name="gridRadios"
                                                            (click)="this.changeTelephoneNumber(direction)"
                                                            [(ngModel)]="this.addressMain" [id]="'gridRadios'+i"
                                                            [value]="direction.idAddress"
                                                            [attr.checked]="direction.mainAddress">
                                                        <label class="form-check-label labelDirections" for="gridRadios1"
                                                            style="font-size: 12px">
                                                            {{direction.contactName}} {{direction.contactSurname}} <br>

                                                            {{direction.addressType}} {{direction.addressName}},
                                                            {{direction.number}}<br>

                                                            {{direction.postalCode}}
                                                            {{this.checkLocalityProvince(direction.localty,direction.province)}}<br>

                                                            <ng-container *ngIf="direction.localty !== direction.province">
                                                                {{direction.province}}</ng-container>

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="this.dataDirections.length == 0">
                                                <div class="col-md-12">
                                                    <div class="alert alert-info">
                                                        No tiene ninguna dirección permitida guardada en su perfil.
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>

            <div class="col-12 row justify-content-center lineaButtonCanje mt-5 mb-5">
                <ng-container *ngIf="this.disableButtonExchange">
                    <button class="btn btnNaosProduct"
                    [disabled]="this.bag.length == 0">Canjear</button>
                </ng-container>
                <ng-container *ngIf="!this.disableButtonExchange">
                    <button (click)="this.sendExchangeProduct()" class="btn btnNaosProduct"
                    [disabled]="this.bag.length == 0">Canjear</button>
                </ng-container>
            </div>

        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="modalCanjeProductos" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="px-4">
                    <ng-container *ngIf="this.loaderConfirm">
                        <div class="row loader">
                            <div class="col-md-12">
                                <img src="../../../assets/images/loader.gif" />
                            </div>
                        </div><br><br>
                    </ng-container>

                    <ng-container *ngIf="!this.loaderConfirm">
                        <h5 class="blue-text text-center my-2">Vas a realizar tu pedido, confirmanos los datos</h5>
                        <hr class="w-100">

                        <h5 class="text-center my-4">Productos</h5>
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table table-responsive text-center">
                                    <tbody>
                                        <tr class="tabledesign"  *ngFor="let item of this.bag">
                                            <td>{{item.quantity}} uds.</td>

                                            <ng-container *ngIf="item.destiny == 'catalogo'">
                                                <td class="text-left">{{item.jwhat.item_sd}} {{item.jwhat.attribute_desc.es}}</td>
                                            </ng-container>
                                            <ng-container *ngIf="item.destiny == 'detalle'">
                                                <ng-container *ngIf="item.attributes !== undefined">
                                                    <td>{{item.jwhat.item_sd}} {{item.attributes[0].value.es}}</td>
                                                </ng-container>
                                                <ng-container *ngIf="item.attributes == undefined">
                                                    <td>{{item.jwhat.item_sd}}</td>
                                                </ng-container>
                                            </ng-container>

                                            <td class="text-right">{{this.showLoyaltyUnitPrize(item.jwhat.payments.loyalty_units) | number:'1.0-3':'de'}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" class="text-total">Total de Estrellas</td>
                                            <td class="total-result">
                                                <span class="pr-2">{{this.scoreTotal | number:'1.0-3':'de'}}</span>
                                                <img src="../../../assets/images/Group.png" style="height: 20px;"/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr class="w-100">

                        <div class="row" *ngIf="this.typeSendDomicilio">
                            <div class="col-md-12">
                                <h5 class="text-center">Dirección de entrega</h5>
                            </div>
                        </div>

                        <div class="row row justify-content-center" *ngIf="this.typeSendDomicilio">
                            <div class="col-md-12 mt-4 my-2">
                                <div class="row text-body-modal justify-content-center">
                                    <div class="col-md-12 row justify-content-center">
                                        Persona de contacto: {{this.objectResumen.contacto}}
                                    </div>
                                </div>
                                <div class="row text-body-modal justify-content-center">
                                    <div class="col-md-12 row justify-content-center">
                                        Telf: {{this.objectResumen.telefono}}
                                    </div>
                                </div>
                            </div>

                            <div class="row col-md-12 justify-content-center my-4 px-0">
                                <div class="col-md-4 row justify-content-center pl-4 pt-2">
                                    <span class="iconDirection iconAdress"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                                </div>

                                <div class="col-md-8">
                                    <div class="row text-body-modal">
                                        <div class="col-md-12">
                                            C/ {{this.objectResumen.calle}}
                                        </div>
                                    </div>
                                    <div class="row text-body-modal">
                                        <div class="col-md-6" *ngIf="this.objectResumen.portal !== ''">
                                            Ptal: {{this.objectResumen.portal}}
                                        </div>
                                        <div class="col-md-6" *ngIf="this.objectResumen.escaleras !== ''">
                                            Esc: {{this.objectResumen.escaleras}}
                                        </div>
                                        <div class="col-md-6" *ngIf="this.objectResumen.planta !== ''">
                                            Plta: {{this.objectResumen.planta}}
                                        </div>
                                        <div class="col-md-6" *ngIf="this.objectResumen.puerta !== ''">
                                            Pta: {{this.objectResumen.puerta}}
                                        </div>
                                    </div>
                                    <div class="row text-body-modal">
                                        <div class="col-md-12">
                                            C.P: {{this.objectResumen.cp}}, {{this.objectResumen.localidad}}
                                        </div>
                                    </div>
                                    <div class="row text-body-modal">
                                        <div class="col-md-12">
                                            Otros datos: {{this.objectResumen.otrosdatos}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-2" *ngIf="this.typeSendDomicilio">
                            <div class="col-md-12 row text-body-modal justify-content-center">
                                Transporte: SEUR
                            </div>
                        </div>

                        <div class="row mb-4" *ngIf="this.typeSendCenter == '1'">
                            <div class="col-md-12">
                                <h5 class="text-center">Dirección de recogida</h5>
                            </div>
                        </div>

                        <div class="row mb-4 ml-1" *ngIf="this.typeSendCenter == '1'">
                            <div class="col-md-12 row justify-content-center text-body-modal" *ngIf="this.idpharmacyAllowed || this.idsteticAllowed">
                                Centro: {{this.centroRecogida.jwhere.where_ld}}
                            </div>
                        </div>

                        <div class="row mb-4 mt-4" *ngIf="this.typeSendCenter == '1' && this.centroRecogida !== undefined">
                            <div class="col-md-12" *ngIf="this.channelIdSite === 2">
                                <div class="row text-body-modal">
                                    <div class="col-md-12 row justify-content-center">
                                        Dirección: {{this.centroRecogida.jwhereAddress.normalizedDeyde.nombreVia}}, Nº:
                                        {{this.centroRecogida.jwhereAddress.normalizedDeyde.numeroVia}}
                                    </div>
                                </div>
                                <div class="row text-body-modal">
                                    <div class="col-md-12 row justify-content-center">
                                        C.P: {{this.centroRecogida.jwhereAddress.postal_cd}},
                                        {{this.checkLocalityProvinceResumen(this.centroRecogida.jwhereAddress.normalizedDeyde.poblacion, this.centroRecogida.jwhereAddress.normalizedDeyde.provincia)}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="this.channelIdSite === 1">
                                <div class="row text-center" *ngIf="this.centroRecogida.jwhere.url !== ''">

                                    <div class="col-md-12">
                                        <ng-template #popContent>
                                            <p [innerHTML]="this.centroRecogida.jwhere.url"></p><br>
                                            <!-- 1.- Accede a: <a [href]="this.centroRecogida.jwhere.url" target="_blank">{{this.centroRecogida.jwhere.url}}</a>.<br>
                                            2.- Haz clic en el botón "añadir" del producto que quieres recibir como regalo<br>
                                            3.- Ve al carrito<br>
                                            4.- Introduce el Código del producto (ver email: empieza por NC...) en el apartado ¿Tiene un código promocional?<br>
                                            5.- Valida el código y...¡listos! Ya tienes tu producto favorito gratis<br>
                                            6.- Tramitar compra, ¡disfrútalo!<br> -->
                                        </ng-template>

                                        <p class="cursor-pointer text-center">
                                            ¿Cómo canjear el producto en la web?
                                            <i style="font-size: 16px !important" [placement]="['right', 'bottom', 'auto']" [ngbPopover]="popContent"
                                            class="fas fa-info-circle iconInfo ml-0"></i>
                                        </p>
                                        <!-- <p id="colapsetext" [ngClass]="textHidden ? 'text-center collapse' : 'text-center'" [innerHTML]="this.centroRecogida.jwhere.url"></p> -->
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="this.channelIdSite === 3">
                                    <div class="row">
                                            <div class="col-md-12 row justify-content-center text-body-modal">
                                                Dirección: {{this.centroRecogida.jwhereAddress.normalizedDeyde.nombreVia}}, Nº:
                                                {{this.centroRecogida.jwhereAddress.normalizedDeyde.numeroVia}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 row justify-content-center text-body-modal">
                                                C.P: {{this.centroRecogida.jwhereAddress.postal_cd}},
                                                {{this.checkLocalityProvinceResumen(this.centroRecogida.jwhereAddress.normalizedDeyde.poblacion, this.centroRecogida.jwhereAddress.normalizedDeyde.provincia)}}
                                            </div>
                                        </div>
                                <div class="row text-center" *ngIf="this.centroRecogida.jwhere.url !== ''">
                                        <div class="col-md-12">
                                            <ng-template #popContent>
                                                <p [innerHTML]="this.centroRecogida.jwhere.url"></p><br>
                                                <!-- 1.- Accede a: <a [href]="this.centroRecogida.jwhere.url" target="_blank">{{this.centroRecogida.jwhere.url}}</a>.<br>
                                                2.- Haz clic en el botón "añadir" del producto que quieres recibir como regalo<br>
                                                3.- Ve al carrito<br>
                                                4.- Introduce el Código del producto (ver email: empieza por NC...) en el apartado ¿Tiene un código promocional?<br>
                                                5.- Valida el código y...¡listos! Ya tienes tu producto favorito gratis<br>
                                                6.- Tramitar compra, ¡disfrútalo!<br> -->
                                            </ng-template>

                                            <p class="cursor-pointer">
                                                ¿Cómo canjear el producto en la web?
                                                <i style="font-size: 16px !important" [placement]="['right', 'bottom', 'auto']" [ngbPopover]="popContent"
                                                class="fas fa-info-circle iconInfo ml-0"></i>
                                            </p>
                                            <!-- <p id="colapsetext" [ngClass]="textHidden ? 'text-center collapse' : 'text-center'" [innerHTML]="this.centroRecogida.jwhere.url"></p> -->
                                        </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="row px-4 m-2 justify-content-center mb-3" *ngIf="this.bag.length > 0">
                    <button type="button" class="col-md-5 m-2 btn btn-secondary btnNaosProductModal" data-bs-dismiss="modal" (click)="this.cleanExchanges()">Cerrar</button>

                    <!-- Primero comprobamos si son productos fisicos -->
                    <ng-container *ngIf="this.bag[0].json_type_ld !== 'JREWARDS_PO'">
                        <button *ngIf="this.typeSendDomicilio" (click)="this.exchangeProductsDomicilio()" type="button" class="col-md-5 m-2 btn btnNaosProductModal" [disabled]="this.loaderConfirm">
                            Confirmar
                        </button>
                        <button *ngIf="this.typeSendCenter == '1'" (click)="this.exchangeProductsCenter()" type="button" class="col-md-5 m-2 btn btnNaosProductModal" [disabled]="this.loaderConfirm">
                            Confirmar
                        </button>
                    </ng-container>
                    <ng-container *ngIf="this.bag[0].json_type_ld == 'JREWARDS_PO'">
                        <button (click)="this.exchangeProductsDigital()" type="button" class="col-md-5 m-2 btn btnNaosProductModal" [disabled]="this.loaderConfirm">
                            Confirmar
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="exampleModalNotificationBag" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12 filaLogo">
                            <img src="../../../assets/images/LOGO-NaosClub1.png" />
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-md-12 filaLogo2" id="contenidodiv">
                            {{ this.textModal }}
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" (click)="this.redirectPopupNotification()"
                        class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
                </div>
            </div>
        </div>
    </div>
