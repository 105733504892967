<div class="container">
    <div class="row loader" *ngIf="!this.loader">
        <div class="col-md-12">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>
    <div class="row" *ngIf="this.loader">
        <div class="col-12 col-md-6">
            <img class="imagenDetalle mw-100 mb-5 mb-md-0" [src]="this.productDetail.jwhat.product_info.multimedia.mainImage[1].url" />
        </div>
        <div class="col-12 col-md-6">
            <div class="row title">
                <div class="col-md-12">
                    <h4>{{this.productDetail.jwhat.product_info.item_sd.es}}</h4>
                </div>
            </div>
            <div class="row subtitle mb-3">
                <div class="col-md-12">
                    {{this.productDetail.jwhat.product_info.item_ld.es}}
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-12">
                    <div class="form-group row">
                        <label *ngIf="this.productDetail.attributes !== undefined" for="format" class="col-sm-6 col-form-label">Formato {{ this.productDetail.attributes[0].value.es}}</label>
                    </div>
                </div>
            </div>
            <div class="row text mb-5">
                <div class="col-md-12">
                    <p class="card-text"><img src="../../../../assets/images/Group@2x.png" /> {{this.productDetail.jwhat.payments.loyalty_units[0].loyalty_unit_quantity | number:'1.0-3':'de' }} estrellas</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button (click)="this.addProductToBag(this.productDetail)" class="btn btnNaosProduct">Añadir a la cesta</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-naosnotification [msgModal]="this.textModal"></app-naosnotification> -->
<div class="modal fade" id="exampleModalNotificationDetalle" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 filaLogo">
                <img src="../../../assets/images/LOGO-NaosClub1.png" />
            </div>
          </div><br>
          <div class="row">
              <div class="col-md-12 filaLogo2" id="contenidodiv">
                {{ this.textModal }}
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
  </div>