<!-- Loader icon spinner -->
<ng-container *ngIf="!this.loader">
    <div class="row loader">
        <div class="col-12 text-center">
            <img src="../../../assets/images/loader.gif" />
        </div>
    </div>
</ng-container>

<!-- ? -->
<!-- <div *ngIf="this.loader" class="container mw-90 w-mobile-100 mw-mobile-100">
    <div class="row displayIpad">
        <div class="col-12 my-4">
            <div id="carouselExampleControls" #carousel class="carousel w-100 slide slideMargin" data-ride="carousel">
                <div class="carousel-inner">
                    <ng-container *ngFor="let banner of this.arrayBannerAdvServer;let i = index">
                        <div [ngClass]="i === 0 ? 'carousel-item active' : 'carousel-item'">
                            <a *ngIf="banner.content.clikLink.es !== '' && banner.content.clikLink.es.indexOf('www') === -1 && banner.content.clikLink.es.indexOf('https://') === -1 && banner.content.images.web !== undefined" [href]="banner.content.clikLink.es"> <img class="d-block w-100" [src]="banner.content.images.web.es[0].url" alt="First slide"></a>
                            <a class="cursor-pointer" *ngIf="banner.content.clikLink.es !== '' && banner.content.clikLink.es.indexOf('www') > -1 && banner.content.images.web !== undefined" target="_blank" [href]="banner.content.clikLink.es"> <img class="d-block w-100" [src]="banner.content.images.web.es[0].url" alt="First slide"></a>
                            <a class="cursor-pointer" *ngIf="banner.content.clikLink.es !== '' && banner.content.clikLink.es.indexOf('https://') > -1 && banner.content.images.web !== undefined" (click)="this.goToUrlRara(banner.content.clikLink.es)"> <img class="d-block w-100" [src]="banner.content.images.web.es[0].url" alt="First slide"></a>
                            <a *ngIf="banner.content.clikLink.es == '' && banner.content.images.web !== undefined"> <img class="d-block w-100" [src]="banner.content.images.web.es[0].url" alt="First slide"></a>
                        </div>
                    </ng-container>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" id="nextButton" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
    <div class="row displayMobile">
        <div class="col-12 my-4">
            <div id="carouselExampleControlsMobile" #carousel class="carousel w-100 slide slideMargin" data-ride="carousel">
                <div class="carousel-inner">
                    <ng-container *ngFor="let bannermobile of this.arrayBannerAdvServer;let x = index">
                        <div [ngClass]="x === 0 ? 'carousel-item active' : 'carousel-item'">
                            <a *ngIf="bannermobile.content.clikLink.es !== '' && bannermobile.content.clikLink.es.indexOf('www') === -1 && bannermobile.content.clikLink.es.indexOf('https://') === -1 && bannermobile.content.images.mobile !== undefined" [href]="bannermobile.content.clikLink.es"> <img class="d-block w-100" [src]="bannermobile.content.images.mobile.es[0].url" alt="First slide"></a>
                            <a class="cursor-pointer" *ngIf="bannermobile.content.clikLink.es !== '' && bannermobile.content.clikLink.es.indexOf('www') > -1 && bannermobile.content.images.mobile !== undefined" target="_blank" [href]="bannermobile.content.clikLink.es"> <img class="d-block w-100" [src]="bannermobile.content.images.mobile.es[0].url" alt="First slide"></a>
                            <a class="cursor-pointer" *ngIf="bannermobile.content.clikLink.es !== '' && bannermobile.content.clikLink.es.indexOf('https://') > -1 && bannermobile.content.images.mobile !== undefined" (click)="this.goToUrlRara(bannermobile.content.clikLink.es)"> <img class="d-block w-100" [src]="bannermobile.content.images.mobile.es[0].url" alt="First slide"></a>
                            <a *ngIf="bannermobile.content.clikLink.es == '' && bannermobile.content.images.mobile !== undefined"> <img class="d-block w-100" [src]="bannermobile.content.images.mobile.es[0].url" alt="First slide"></a>
                        </div>
                    </ng-container>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControlsMobile" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" id="nextButton" href="#carouselExampleControlsMobile" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</div> -->

<app-naos-menu [contenidoApartadosHome]="this.homeContentMenu" *ngIf="this.loader" class="mw-90 w-mobile-100 mw-mobile-100"></app-naos-menu>

<!-- popups -->
<div *ngIf="this.loaderModal" class="modal fade" id="modalOfertaFree" tabindex="-1" role="dialog" aria-labelledby="modalOfertaFree" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-offer">
                    <div class="container-gris">

                    </div>
                    <div class="container-photo">
                        <img class="imagenFreeProduct" [src]="this.productInfoUrl" />
                    </div>
                    <div class="container-rosa">
                        <div class="row">
                            <div class="col-md-6">

                            </div>
                            <div class="col-md-6 columnaTexto">
                                <p class="textoRegalo">¡Tienes un regalo<br> pendiente de canjear!</p>
                                <p class="nombreRegalo">{{this.scoreFreeClient.description}}</p>
                                <button (click)="this.goToStore()" class="btn btnCanjear">
                                    Canjear
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalNotificationDashboard" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 filaLogo">
                        <img src="../../../assets/images/LOGO-NaosClub1.png" />
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-md-12 filaLogo2" id="contenidodiv">
                        {{ this.textModal }}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="this.redirectPopupNotification()" class="btn btn-secondary btnPopUpFormsCerrar" data-bs-dismiss="modal">Aceptar</button>
            </div>
        </div>
    </div>
</div>
