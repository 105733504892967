<div class="container w-80 w-mobile-100 mw-mobile-100">
    <div class="row justify-content-center">
        <div class="col-12 col-md-7 col-lg-4  text-center my-5 align-self-center"><img class="w-100 h-auto" src="../../../assets/images/LOGO-NaosClub1@2x.png" /></div>
    </div>
    <div class="row" style="text-align: center">
        <div class="col-md-12">
            <h5>CONDICIONES GENERALES DE ADHESIÓN A NAOS CLUB</h5>
        </div>
    </div><br>
    <div class="row">
        <div class="col-md-12">
            <h6>1. ÁMBITO, OBJETO Y DURACIÓN DEL PROGRAMA DE FIDELIZACIÓN</h6><br>
            <p>
                NAOS CLUB es un programa de fidelización que la compañía NAOS SKIN CARE SPAIN S.L.U., con domicilio social en avenida Diagonal nº 538, 1º, Barcelona 08006, Barcelona (España) y titular del CIF número B-61.424.974 (en adelante, “NAOS”) pone a disposición de sus clientes con el fin de fidelizarlos ofreciéndoles de forma exclusiva y personalizada recomendaciones de productos de las marcas BIODERMA e INSTITUT ESTHEDERM y promociones, tales como, descuentos, sorteos y concursos, regalos y ofertas especiales.
            </p>
            <p>
                Las presentes condiciones generales de adhesión al programa de fidelización NAOS CLUB (en adelante, “Condiciones Generales”) tienen por objeto definir las características del programa de fidelización NAOS CLUB y regular los requisitos y condiciones de adhesión, sus beneficios y el funcionamiento del mismo. Las presentes Condiciones Generales estarán a disposición de los Afiliados para su consulta en las páginas webs <a href="https://naosclub.bioderma.es/">naosclub.bioderma.es</a> y <a href="https://naosclub.esthederm.es/">naosclub.esthederm.es</a> (en adelante, las “Webs de NAOS CLUB”). 
            </p>
            <p>
                La adhesión al programa de fidelización NAOS CLUB por parte de los clientes de NAOS (en adelante, los “Afiliados”) es totalmente libre, gratuita y voluntaria e implica la aceptación íntegra y sin reservas por parte de los Afiliados de las presentes Condiciones Generales, comprometiéndose al cumplimiento de lo estipulado en las mismas en todo momento.
            </p>
            <p>
                El programa de fidelización NAOS CLUB tiene naturaleza indefinida si bien NAOS podrá extinguirlo en cualquier momento, en caso de considerarlo oportuno, notificándolo a los Afiliados por escrito con una antelación mínima de tres (3) meses a su fecha de finalización.
            </p>
        </div>
    </div><br>
    <div class="row">
        <div class="col-md-12">
            <h6>2. ¿QUIÉN PUEDE FORMAR PARTE DE NAOS CLUB?</h6><br>
            <p>
                Podrá adherirse al programa de fidelización NAOS CLUB cualquier cliente de NAOS que sea una persona física mayor de edad con plena capacidad legal y tenga su domicilio en España, excepto en Ceuta y Melilla (en adelante, el "Territorio"). Andorra queda asimismo excluida del Territorio. 
            </p>
            <p>
                Queda terminantemente prohibida la adhesión a NAOS CLUB por menores de edad. A este respecto, NAOS se reserva la facultad de comprobar la veracidad de los datos facilitados por los Afiliados, en especial su edad, tanto en el momento de su registro como en cualquier otro momento, pudiendo solicitar a tal fin la documentación acreditativa que, en cada caso, considere oportuna (DNI, NIE, etc).
            </p>
        </div>
    </div><br>
    <div class="row">
        <div class="col-md-12">
            <h6>3. ¿CÓMO ADHERIRSE A NAOS CLUB? REGISTRO Y PERFIL DEL USUARIO</h6><br>
            <p>
                La adhesión al programa de fidelización NAOS CLUB es libre, voluntaria y gratuita. Los clientes de NAOS que quieran adherirse al programa de fidelización NAOS CLUB deberán cumplimentar el formulario de registro vigente en cada momento disponible en las Webs de NAOS CLUB. Para registrarse en el programa de fidelización NAOS CLUB, los clientes deberán cumplimentar de forma veraz, correcta y completa todos aquellos campos marcados como obligatorios en el formulario de registro.
            </p>
            <p>
                Una vez correctamente cumplimentado y enviado el formulario de registro, el Afiliado recibirá un email de bienvenida en la dirección de correo electrónico facilitada en el formulario de registro comunicándole su admisión  en el programa de fidelización NAOS CLUB. Con la recepción del email de bienvenida en el programa de fidelización NAOS CLUB, el Afiliado podrá acceder a su Perfil de Usuario (en adelante, el “Perfil de Usuario”) en las Webs de NAOS CLUB.
            </p>
            <p>
                La condición de afiliado al programa de fidelización NAOS CLUB es individual, personal e intransferible.
            </p>
            <p>
                Cada Afiliado podrá registrarse una única vez en el programa de fidelización, estando expresamente prohibidos los registros múltiples por parte de un mismo Afiliado, incluso mediante diferentes cuentas de correo electrónico. Queda igualmente prohibido el registro en el programa de fidelización NAOS CLUB proporcionando información falsa o incorrecta y/o datos de terceros. NAOS cancelará de forma automática, sin previo aviso y sin derecho a indemnización ninguno aquellas cuentas de usuario que se den de alta incumpliendo lo dispuesto en las presentes Condiciones Generales, reservándose cualesquiera otras acciones que le puedan corresponder, incluidas las legales y la baja de NAOS CLUB del Afiliado que incumpla lo anteriormente dispuesto.
            </p>
            <p>
                En caso de que el potencial Afiliado cumplimente el formulario de registro de forma defectuosa, incorrecta o incompleta NAOS podrá contactar con aquel por cualquier medio de comunicación con el fin de que el potencial Afiliado pueda corregir, aclarar o completar la información correspondiente.
            </p>
            <p>
                Al cumplimentar el formulario de registro y solicitar su afiliación al programa de fidelización NAOS CLUB, el Afiliado manifiesta a todos los efectos legales oportunos comprender y aceptar íntegramente y sin reservas las presentes Condiciones Generales.
            </p>
        </div>
    </div><br>
    <div class="row">
        <div class="col-md-12">
            <h6>4. ¿EN QUÉ CONSISTE EL PROGRAMA DE FIDELIZACIÓN NAOS CLUB Y QUÉ VENTAJAS OFRECE?</h6><br>
            <p>
                El programa de fidelización NAOS CLUB es un programa que busca fidelizar a los clientes de NAOS basándose en un mayor conocimiento de los Afiliados y sus características, gustos, intereses, preferencias y hábitos de compra con el fin de ofrecerles una atención especial y preferente, así como un servicio más personalizado y exclusivo. Así, las principales ventajas del programa de fidelización NAOS CLUB son las siguientes:
            </p>
                <ul>
                    <li>Sistema de acumulación de puntos (llamados “Estrellas NAOS”) por compra de productos de las marcas BIODERMA e INSTITUT ESTHEDERM;</li>
                    <li>Invitaciones a eventos;</li>
                    <li>Diagnósticos de piel personalizados;</li>
                    <li>Recomendaciones personalizadas de productos de las marcas BIODERMA e INSTITUT ESTHEDERM; y</li>
                    <li>Promociones exclusivas, tales como, descuentos, premios, regalos y ofertas especiales.</li>
                </ul>
            <p>
                NAOS se reserva la facultad de modificar, ampliar o cancelar en cualquier momento y sin derecho a indemnización alguna las ventajas y beneficios que ofrece el programa de fidelización NAOS CLUB, si bien informará debidamente de dichas modificaciones a los Afiliados.
            </p>
        </div>
    </div><br>
    <div class="row">
        <div class="col-md-12">
            <h6>5. SISTEMA DE ESTRELLAS NAOS</h6><br>
            <p>
                La compra de productos de las marcas BIODERMA e INSTITUT ESTHEDERM por el Afiliado en los Puntos de Venta adheridos a NAOS CLUB conllevará la acumulación de Estrellas NAOS que los Afiliados podrán canjear por determinados productos de las citadas marcas. Las Estrellas NAOS no podrán transferirse a terceros o a otros Afiliados de NAOS CLUB, ni tampoco canjearse por dinero.
            </p>
            <p>
                En el caso de los Afiliados residentes en las Islas Canarias, el sistema de acumulación de Estrellas NAOS solamente será aplicable a la compra de productos de la marca BIODERMA. En ningún caso los Afiliados residentes en las Islas Canarias podrán acumular Estrellas NAOS por la compra de productos de INSTITUT ESTHEDERM, ni tampoco canjear las Estrellas NAOS obtenidas por productos de la citada marca.
            </p>
        </div>
    </div><br>
    <div class="row">
        <div class="col-md-12">
            <h6>5.1	Puntos de Venta excluidos de NAOS CLUB</h6><br>
            <p>
                Puntos de Venta son todos aquellos establecimientos (farmacias, tiendas de parafarmacia y centros de estética principalmente, aunque no los únicos) ubicados en el Territorio y en los que los Afiliados pueden comprar productos de las marcas BIODERMA e INSTITUT ESTHEDERM (solo BIODERMA en las Islas Canarias). No todos los Puntos de Venta están adheridos a NAOS CLUB. Los Afiliados pueden consultar en todo momento el listado actualizado de los Puntos de Venta excluidos de NAOS CLUB <a class="linkList" (click)="this.goToExclusion()">aquí</a>. Las compras de productos de las marcas BIODERMA e INSTITUT ESTHEDERM realizadas en Puntos de Venta excluidos de NAOS CLUB no permiten la acumulación de Estrellas NAOS, por ello NAOS recomienda a los Afiliados revisar regularmente el listado de Puntos de Venta excluidos de NAOS CLUB para asegurarse de que aprovechan al máximo las ventajas y beneficios del mismo.
            </p>
        </div>
    </div><br>
    <div class="row">
        <div class="col-md-12">
            <h6>5.2	Puntos de Venta adheridos a NAOS CLUB</h6><br>
            <p>
                Los Puntos de Venta adheridos a NAOS CLUB son todos aquellos Puntos de Venta no excluidos de NAOS CLUB y se clasifican en Ordinarios o Favoritos. Cada Afiliado puede designar como máximo al mismo tiempo una farmacia y un centro de estética como Puntos de Venta Favoritos. El resto de Puntos de Venta adheridos a NAOS CLUB que no hayan sido específicamente designados por el Afiliado como Puntos de Venta Favoritos serán Puntos de Venta Ordinarios. Una vez designados por el Afiliado el/los Punto/s de venta Favoritos , el Afiliado no podrá modificarlo/s salvo petición expresa por escrito remitida al correo electrónico <a href="mailTo:infonaosclub@es.naos.com">infonaosclub@es.naos.com</a>.
            </p>
        </div>
    </div><br>
    <div class="row">
            <div class="col-md-12">
                    <h6>5.3	Sistema de Estrellas NAOS</h6><br>
                    <p>
                        El número de Estrellas NAOS que el Afiliado acumula por la compra de productos de las marcas BIODERMA e INSTITUT ESTHEDERM (solo BIODERMA en las Islas Canarias) en Puntos de Venta adheridos a NAOS CLUB varía dependiendo del tipo de Punto de Venta donde haya adquirido dichos productos:
                    </p>
            </div>
        </div><br>
    <div class="row" style="text-align: center">
        <div class="col-md-12 table-responsive" style="text-align: center">
                        <table class="table tableEstrellas table-bordered">
                                <thead>
                                    <tr>
                                        <th>Punto de Venta Ordinario</th>
                                        <th>Punto de Venta de Origen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1€ = 1 estrella <img src="../../../assets/images/Group@2x.png"/></td>
                                        <td>1€ = 2 estrellas <img src="../../../assets/images/Group@2x.png"/></td>
                                    </tr>
                                </tbody>
                            </table>
        </div>
    </div><br>
    <div class="row">
            <div class="col-md-12">
                <p>
                    En el caso de haber adquirido los productos de las marcas BIODERMA y/o INSTITUT ESTHEDERM en un Punto de Venta Ordinario adherido a NAOS CLUB, el Afiliado obtendrá un número de Estrellas NAOS equivalente a una estrella por cada cada euro abonado por cada producto de las marcas BIODERMA e INSTITUT ESTHEDERM hasta un límite del cinco por ciento (5%) de su valor. En caso de haber adquirido los productos de las marcas BIODERMA y/o INSTITUT ESTHEDERM en un Punto de Venta Favorito adherido a NAOS CLUB, el Afiliado obtendrá un número de Estrellas NAOS equivalente a dos estrellas por cada euro abonado por cada producto adquirido de las marcas BIODERMA y/o INSTITUT ESTHEDERM hasta un límite del diez por ciento (10%) de su valor. El número de Estrellas NAOS que recibirá el Afiliado por la compra de cada producto BIODERMA y/o INSTITUT ESTHEDERM estára limitado siempre y en todo caso al cinco por ciento (5%), en el caso de Puntos de Venta Ordianarios, o el diez por ciento (10%), en el caso de Puntos de venta Favoritos, del precio de cada producto BIODERMA y/o INSTITUT ESTHEDERM adquirido.
                </p>
                <p>
                    La obtención de Estrellas NAOS no requiere de un valor mínimo de compra, ni la compra de un número mínimo de productos de NAOS.
                </p>
                <p>
                    En el caso de los Afiliados residentes las Islas Canarias, el sistema de Estrellas NAOS será aplicable únicamente a la compra de productos de la marca BIODERMA. 
                </p>
                <p>
                    El Afiliado podrá comprobar el número de Estrellas NAOS acumuladas en cualquier momento Perfil de Usuario en su Perfil de Usuario en las Webs de NAOS CLUB.
                </p>
            </div>
        </div><br>
        <div class="row">
                <div class="col-md-12">
                    <h6>5.4	Registro de compras de productos BIODERMA e INSTITUT ESTHEDERM y acumulación de Estrellas NAOS en Perfil de Usuario</h6><br>
                    <p>
                        Para convertir en Estrellas NAOS el importe de los productos de las marcas BIODERMA o INSTITUT ESTHEDERM adquiridos en los Puntos de Venta adheridos a NAOS CLUB, el Afiliado deberá sacar una foto de su ticket de compra y subir la foto al apartado “Mis Compras” de su Perfil de Usuario en las Webs de NAOS CLUB.
                    </p>
                    <p>
                        Es obligación del Afiliado conservar el ticket de compra cuya fotografía ha subido al apartado “Mis Compras” de su Perfil de Usuario hasta que finalice el Plazo de Reclamación, esto es, catorce (14) días naturales desde la fecha en que se registran en el apartado “Mis Compras” de Perfil de Usuario del Afiliado las compras realizadas de los productos de las marcas BIODERMA e INSTITUT ESTHEDERM. 
                    </p>
                    <p>
                        El Afiliado dispondrá de un plazo máximo de un (1) mes desde la realización de su compra para subir la foto de su ticket de compra al apartado “Mis Compras” de su Perfil de Usuario en las Webs de NAOS CLUB y obtener así las Estrellas NAOS correspondientes por dichas compras. En caso de que el Afiliado no suba la foto de su ticket de compra en el plazo establecido, perderá la posibilidad de canjear el importe de los productos adquiridos de la marca BIODERMA o INSTITUT ESTHEDERM por Estrellas NAOS. 
                    </p>
                    <p>
                        En un plazo máximo de dos (2) días laborables desde la fecha en que el Afiliado suba la foto de su ticket de compra al apartado “Mis Compras” de Perfil de Usuario en las Webs de NAOS CLUB se registrarán las Estrellas NAOS obtenidas por dicha compra, acumulándose a las Estrellas NAOS que ya tuviera anteriormente. 
                    </p>
                    <p>
                        Dado que en las Islas Canarias el sistema de Estrellas NAOS es exclusivamente aplicable a la compra de productos de la marca BIODERMA, en ningún caso los tickets de compra de productos de la marca INSTITUT ESTHEDERM subidos por un Afiliado residente en las Islas Canarias a su Perfil de Usuario conllevará la acumulación de Estrellas NAOS. En el supuesto de que un mismo ticket de compra contenga productos de ambas marcas (BIODERMA e INSTITUT ESTHEDERM) NAOS solamente otorgará al Afiliado las Estrellas NAOS que le correspondan por los productos adquiridos de la marca BIODERMA.
                    </p>
                    <p>
                        El plazo de caducidad de las Estrellas NAOS obtenidas es de dos (2) años desde su registro en Perfil de Usuario del Afiliado. Una vez transcurrido el antecitado plazo de dos (2) años, las Estrellas NAOS no canjeadas caducarán automáticamente y el Afiliado perderá dichas estrellas de forma irrevocable y sin derecho a indemnización o compensación alguna. 
                    </p>
                </div>
            </div><br>
            <div class="row">
                <div class="col-md-12">
                    <h6>5.5	Límite al número de tickets a subir mensualmente por los Afiliados</h6><br>
                    <p>
                        Con la intención de prevenir prácticas fraudulentas por parte de los Afiliados de NAOS CLUB, cada Afiliado podrá subir al apartado “Mis Compras” de su Perfil de Usuario en las Webs de NAOS CLUB un número máximo de tres (3) tickets de compra en cada mes natural.
                    </p>
                </div>
            </div><br>
            <div class="row">
                    <div class="col-md-12">
                        <h6>5.6	Procedimiento de reclamación por lectura incorrecta de ticket de compra</h6><br>
                        <p>
                            El Afiliado dispone de un plazo máximo de catorce (14) días naturales desde la fecha en que se registran en el apartado “Mis Compras” de su Perfil de Usuario las compras realizadas de los productos de las marcas BIODERMA e INSTITUT ESTHEDERM (en adelante, el “Plazo de Reclamación”) para reclamar ante NAOS la incorrecta lectura de su ticket de compra y, en consecuencia, el incorrecto registro de productos adquiridos y acumulación de Estrellas NAOS.
                        </p>
                        <p>
                            En caso de que el Afiliado advierta cualquier error en la lectura realizada por NAOS de la fotografía de su ticket de compra subida al apartado “Mis Compras” de su Perfil de Usuario y, por tanto, de los productos BIODERMA o INSTITUT ESTHEDERM adquiridos y en la acumulación de Estrellas NAOS, el Afiliado podrá reclamar la correción de dicho error comunicándolo a NAOS  mediante petición expresa remitida al correo electrónico infonaosclub@es.naos.com adjuntando siempre su ticket de compra tachando, tapando o eliminando de forma que no sean visibles aquellos datos relativos a productos que no sean de las marcas BIODERMA e INSTITUT ESTHEDERM.
                        </p>
                        <p>
                            Sin perjuicio de lo anterior, NAOS almacenará de forma segura y confidencial las fotos de los tickets de compra subidas por el Afiliado al apartado “Mis Compras” de su Perfil de Usuario durante el Plazo de Reclamación única y exclusivamente para poder tramitar aquellas reclamaciones que el Afiliado pueda presentar en los casos en que el proceso de lectura del ticket de compra no sea posible, contrastando así el contenido del ticket de compra subido por el Afiliado con el proceso de lectura del mismo realizado por NAOS. NAOS no accederá, ni tratará las fotos de los tickets de compra en ningún otro caso, ni para ninguna otra finalidad. Una vez finalizado el Plazo de Reclamación, NAOS eliminará de forma automática, definitiva e irreversible la foto del ticket de compra.
                        </p>
                    </div>
                </div><br>
                <div class="row">
                        <div class="col-md-12">
                            <h6>5.7	Procedimiento para canjear las Estrellas NAOS por Productos Obsequio</h6><br>
                            <p>
                                Los productos de las marcas BIODERMA e INSTITUT ESTHEDERM disponibles en cada momento para su canje por Estrellas NAOS (los “Productos Obsequio”) estarán recogidos en el correspondiente catálogo de Productos Obsequio vigente en cada momento. Los Afiliados residentes en las Islas Canarias solamente podrán canjear sus Estrellas NAOS por Productos Obsequio de la marca BIODERMA.
                            </p>
                            <p>
                                NAOS se reserva la facultad de ampliar, reducir, modificar y/o actualizar sin previo aviso y en cualquier momento el catálogo de Productos Obsequio de las marcas BIODERMA e INSTITUT ESTHEDERM disponibles para su canje por nuevas Estrellas NAOS.
                            </p>
                            <p>
                                Para canjear las Estrellas NAOS acumuladas por Productos Obsequio, el Afiliado deberá acceder al apartado “Canjea tus estrellas” de su Perfil de Usuario donde se desplegará un catálogo de los productos BIODERMA e INSTITUT ESTHEDERM disponibles en cada momento para su canje por Estrellas NAOS y escoger el Producto Obsequio deseado haciendo clic sobre el mismo y añadirlo a la cesta, aceptando.
                            </p>
                            <p>
                                Una vez seleccionado el Producto Obsequio, NAOS generará un código QR y un código alfanumérico equivalente al Código QR con el/los Producto/s Obsequio seleccionado/s y quedará formalizado el canje de Estrellas NAOS, por lo que el Afiliado no tendrá derecho a modificar su elección, ni obtener un producto distinto al seleccionado. El Código QR y el código alfanumérico se almacenarán en el apartado “Canjea tus estrellas” del Perfil de Usuario del Afiliado y también se enviarán por email a la dirección de correo electrónico facilitada por el Afiliado.
                            </p>
                            <p>
                                El Afiliado podrá canjear tantas Estrellas NAOS como quiera en cualquier momento, no habiendo límite máximo, ni mínimo. En caso de que el Afiliado no disponga de suficientes Estrellas NAOS para obtener el Producto Obsequio que desee, aparecerá un aviso en su pantalla informándole de tal circunstancia.
                            </p>
                            <p>
                                Para obtener el/los Producto/s Obsequio, el Afiliado únicamente deberá mostrar el Código QR o el código alfanumérico equivalente obtenido en cualquiera de los Puntos de Venta adheridos a NAOS CLUB.
                            </p>
                            <p>
                                El Afiliado podrá utilizar una única vez cada Código QR o cada código alfanumérico para canjearlo por el/los Producto/s Obsequio seleccionado/s. Tras la entrega del/los Producto/s Obsequio, el Código QR y el código alfanumérico quedarán inhabilitados automáticamente. El Afiliado dispone de un plazo de treinta (30) días naturales desde la recepción del correo electrónico con el Código QR y el código alfanumérico para adquirir el Producto Obsequio. Transcurrido el plazo de treinta (30) días, el Afiliado perderá su derecho a obtener el Producto Obsequio de forma automática. La renuncia al Producto Obsequio o su no recogida en el citado plazo de treinta (30) días no otorgará al Afiliado ningún derecho a indemnización o compensación alguna, así como tampoco conllevará derecho alguno a la devolución de las Estrellas NAOS canjeadas.
                            </p>
                        </div>
                    </div><br>
                    <div class="row">
                            <div class="col-md-12">
                                <h6>5.8	Envío a domicilio de los Productos Obsequio</h6><br>
                                <p>
                                    En caso de que no haya ningún punto de venta adherido a NAOS CLUB en un radio de tres kilómetros (3 km) en torno al domicilio del Afiliado, NAOS enviará el Producto Obsequio al Afiliado a través de una empresa de mensajería a la dirección especificada como su domicilio en el perfil personal de su Perfil de Usuario. En ese caso, una vez finalizado el proceso de canje de Estrellas NAOS, el Afiliado podrá elegir en el apartado “Canjear mis estrellas” la opción “Envío a domicilio”. Posteriormente, deberá indicar la dirección a la que desea que NAOS le haga llegar el Producto Obsequio.
                                </p>
                                <p>
                                    Este servicio estará excluido para los Afiliados residentes en las Islas Canarias, de forma que los Afiliados residentes en las Islas Canarias solamente podrán adquirir los Productos Obsequios directamente en alguno de los Puntos de Venta adheridos a NAOS CLUB.
                                </p>
                            </div>
                        </div><br>
                        <div class="row">
                                <div class="col-md-12">
                                    <h6>6.	PROTECCIÓN DE DATOS </h6><br>
                                    <p>
                                        De conformidad con lo dispuesto en la normativa vigente en materia de protección de datos personales, NAOS informa a los Afiliados que pueden saber más sobre la características y finalidades de los tratamientos que realiza NAOS de sus datos de carácter personal en su <a href="/privacitynew">Política de Privacidad</a>. Asimismo, NAOS informa a los Afiliados que pueden ejercer los derechos reconocidos en la normativa vigente de protección de datos personales solicitándolo por escrito mediante email dirigido a la dirección de correo electrónico <a href="mailTo:protecciondedatos@es.naos.com">protecciondedatos@es.naos.com</a>.
                                    </p>
                                    <p>
                                        Es responsabilidad del Afiliado comunicar a NAOS cualquier modificación o actualización de sus datos personales ya que NAOS asumirá como datos veraces, completos, exactos y actualizados los datos del Afiliado que consten en el perfil personal de su Perfil de Usuario. El Afiliado podrá modificar, rectificar y/o actualizar sus datos personales siempre y en todo momento a través de su Perfil de Usuario en las Webs de NAOS CLUB.
                                    </p>
                                </div>
                            </div><br>
                            <div class="row">
                                    <div class="col-md-12">
                                        <h6>7. DURACIÓN, BAJA VOLUNTARIA Y PÉRDIDA DE LA CONDICIÓN DE AFILIADO</h6><br>
                                        <p>
                                                La condición de afiliado al programa de fidelización NAOS CLUB se otorga por plazo indefinido.
                                        </p>
                                        <p>
                                            El Afiliado puede solicitar su baja de NAOS CLUB en cualquier momento de forma fácil y gratuita a través de las siguientes vías:
                                        </p>
                                        <ul>
                                            <li>Comunicándolo a la dirección de correo electrónico <a href="mailTo:infonaosclub@es.naos.com">infonaosclub@es.naos.com</a></li>
                                            <li>A través de su Perfil de Usuario en el apartado <a class="linkList" (click)="this.goToProfile()">Mi perfil</a> mediante la opción “Darme de baja”. </li>
                                        </ul>
                                        <p>
                                            Al darse de baja del programa de fidelización NAOS CLUB, el Afiliado perderá inmediatamente todas las Estrellas NAOS acumuladas, así como cualquier otro beneficio que pudieran ostentar, sin compensación ninguna ni posibilidad de recuperar los mismos posteriormente o en caso de nueva afiliación.
                                        </p>
                                        <p>
                                            NAOS se reserva la facultad de suspender temporalmente y/o cancelar definitivamente de forma unilateral, sin previo aviso y sin derecho a indemnización alguno la condición de afiliado al programa de fidelización a NAOS CLUB a cualquier Afiliado en caso de que detecte indicios del uso fraudulento, ilícito o contrario a las presentes Condiciones Generales, la ley y/o la buena fe por parte de dicho Afiliado. El uso o aprovechamiento en mala fe, fraudulento, abusivo y/o contrario a las presentes Condiciones Generales, del programa de fidelización NAOS CLUB y/o de sus ventajas y beneficios por parte de cualquier Afiliado conllevará la pérdida sin derecho a compensación o indemnización alguno de su condición de afiliado.
                                        </p>
                                        <p>
                                            El Afiliado será responsable de cualquier daño o perjuicio causado a NAOS o a terceros derivado directa o indirectamente de cualquier uso, participación o aprovechamiento de NAOS CLUB contrario a las presentes Condiciones Generales, la ley, la moral, el orden público o la buena fe.
                                        </p>
                                    </div>
                                </div><br>
                                <div class="row">
                                        <div class="col-md-12">
                                            <h6>8. DURACIÓN Y MODIFICACIÓN DE LAS CONDICIONES GENERALES</h6><br>
                                            <p>
                                                La presente versión de las Condiciones Generales estará vigente hasta la fecha en que entren en vigor una nueva versión de las mismas tras su modificación o actualización total o parcial. En tal caso, en la citada fecha las presentes Condiciones Generales dejarán de estar vigentes, siendo la nueva versión de las mismas la que pasen a regular las condiciones generales de adhesión a NAOS CLUB. 
                                            </p>
                                            <p>
                                                La versión de las Condiciones Generales vigente en cada momento estará siempre a disposición de los Afiliados en las Webs de NAOS CLUB para su consulta.
                                            </p>
                                            <p>
                                                NAOS informa al Afiliado que las presentes Condiciones Generales pueden variar con el tiempo. Así, NAOS se reserva la facultad de modificar las presentes Condiciones Generales cuando ello resulte necesario como consecuencia de cambios en las condiciones de adhesión a NAOS CLUB; modificaciones de NAOS a nivel societario; circunstancias económicas, operativas o de negocio o cambios en el modelo de negocio de NAOS y/o novedades legislativas o jurisprudenciales. En caso de modificación de las presentes Condiciones Generales, NAOS avisará a los Afiliados sobre dicha circunstancia con el fin de que estén debidamente informados y puedan consultar las revisiones, modificaciones o actualizaciones a su entera satisfacción y, en su caso, aceptarlas.
                                            </p>
                                        </div>
                                    </div><br>
                                    <div class="row">
                                            <div class="col-md-12">
                                                <h6>9.	ATENCIÓN AL AFILIADO</h6><br>
                                                <p>
                                                    NAOS pone a disposición del Afiliado un formulario de contacto a través de su Perfil de Usuario y el email de contacto <a href="mailTo:infonaosclub@es.naos.com">infonaosclub@es.naos.com</a> para que pueda ponerse en contacto con NAOS para solucionar cualquier problema o duda.
                                                </p>
                                            </div>
                                        </div><br>
                                        <div class="row">
                                                <div class="col-md-12">
                                                    <h6>10.	GENERALIDADES</h6><br>
                                                    <p>
                                                        Las presentes Condiciones Generales se rigen por la normativa española vigente en cada momento. En caso de controversia, ambas partes se someterán para la resolución de los conflictos al fuero que corresponda al Afiliado según la normativa vigente aplicable.
                                                    </p>
                                                    <p>
                                                        En ningún caso y bajo la falta de ejercicio de sus derechos por parte de NAOS podrá entenderse como una renuncia a los mismos.
                                                    </p>
                                                    <p>
                                                        Los encabezamientos contenidos en las presentes condiciones son meramente orientativas o informativas, de manera que no afectan, califican o amplían la interpretación de las mismas.
                                                    </p>
                                                    <p>
                                                        En el caso de que cualquiera de las cláusulas contenidas en las presentes Condiciones Generales fuera declarada nula, abusiva o inaplicable, total o parcialmente, por un órgano judicial o administrativo, dicha declaración afectará de manera individual a la cláusula en cuestión, teniéndose la misma por no puesta y sin que la declaración de nulidad, abuso o inaplicabilidad pueda extenderse bajo ningún concepto al resto de condiciones, que subsistirán.
                                                    </p>
                                                </div>
                                            </div><br>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    Última versión: 1 de Junio del 2023
                                                </div>
                                            </div><br>
                                            <div class="row" style="text-align: center">
                                                    <div class="col-md-12">
                                                        <button class="btn btnSendTicket" (click)="this.goToAfter()">
                                                            Volver
                                                        </button>
                                                    </div>
                                                </div>
</div>