  <div class="row" style="height: auto">
    <div class="col-12">
      <div class="row" style="border-bottom: 1px solid silver;padding-top: 1%;padding-bottom: 1%">
        <div class="col-6">
          <a href="https://www.bioderma.es/">
              <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" class="logo-bioderma" version="1.1"  width="189" height="38" viewBox="0 0 5357.5 1077.2">
                <title>Inicio</title>
                  <defs>
                    <style>
                      .st0 {
                        fill: #1a3d6f;
                      }
                    </style>
                  </defs>
                  <g>
                    <path class="st0" d="M396.4,350.5c22.9-11.5,94.1-51.2,94.1-147.1S427.9,23.2,273.8,23.2H0v700.5h270.9c125.2,0,249.4-62.3,249.4-199.4s-113.8-170.9-123.8-173.7ZM255.5,305.6H112.6V124.3h162.1c68.6,0,97.4,37.4,97.4,89.7s-55.2,91.6-116.6,91.6ZM401.9,517.5c0,65.9-51.1,105.1-136.8,105.1H112.6v-215.9h153.5c98.2,0,135.8,46.6,135.8,110.8Z"/>
                    <polygon class="st0" points="2623.2 419.2 2945.9 419.2 2945.9 312.3 2623.2 312.3 2623.2 130 2969.9 130 2969.9 23.2 2510.5 23.2 2510.5 723.7 2987.2 723.7 2987.2 616.8 2623.2 616.8 2623.2 419.2"/>
                    <polygon class="st0" points="4199.6 526.4 3988.3 23.2 3834 23.2 3834 723.7 3946.6 723.7 3946.6 201.4 4161.1 723.7 4236.2 723.7 4450.7 201.4 4450.7 723.7 4563.3 723.7 4563.3 23.2 4408.9 23.2 4199.6 526.4"/>
                    <path class="st0" d="M1270.2,5.8c201.4,0,364.7,147.9,364.7,367.6s-163.3,367.6-364.7,367.6c-201.4,0-364.7-147.9-364.7-367.6,0-219.6,163.3-367.6,364.7-367.6M1270.2,112.4c-136.4,0-247,105-247,261,0,155.9,110.6,261,247,261,136.4,0,247-105,247-261s-110.6-261-247-261"/>
                    <path class="st0" d="M1871.4,616.8V130.2h137.7c136.4,0,247,89.5,247,243.2s-131,243.4-267.4,243.4h-117.3ZM2009.1,23.2h-250.3v700.5h250.3c204.8,0,364.7-152,364.7-350.3S2242.1,23.2,2009.1,23.2Z"/>
                    <path class="st0" d="M3586.9,723.8l139.1-.2-222.8-306.6c115.7-23.2,158.2-103.6,158.2-193.5s-59.4-200.4-230.8-200.4h-288.1s0,700.5,0,700.5h112.6v-299.7h57.3c18.5,0,59.7,4.2,86.7,41.4l187.9,258.4ZM3255.2,124.3h153.9c71.1,0,134,17.6,134,98.3s-60.6,100.4-127.8,100.4h-160.1V124.3Z"/>
                    <path class="st0" d="M5227.4,723.7h130.1L5057.6,23.1h-96.9s-308.7,700.5-308.7,700.5h130.1l68.5-168.1h308.3l68.5,168.1ZM4891.8,454.5l113-277.4,113,277.4h-226Z"/>
                    <rect class="st0" x="654.2" y="23.2" width="112.6" height="700.5"/>
                  </g>
                  <g>
                    <path class="st0" d="M.1,910.5h27.9v131.6h66.7v25.3H.1v-156.8Z"/>
                    <path class="st0" d="M239.5,910.5h24.1l67.6,156.8h-31.9l-14.6-35.9h-68l-14.2,35.9h-31.2l68.2-156.8ZM275,1007.5l-24.2-63.8-24.6,63.8h48.7Z"/>
                    <path class="st0" d="M415.5,910.5h61.1c5.9,0,11.7.7,17.5,2.1,5.8,1.4,10.9,3.7,15.4,6.9,4.5,3.2,8.2,7.3,11,12.3,2.8,5,4.2,11.1,4.2,18.2s-2.5,16.2-7.5,21.9c-5,5.8-11.6,9.9-19.7,12.4v.4c4.9.6,9.4,2,13.6,4.1,4.2,2.1,7.8,4.9,10.7,8.2,3,3.3,5.3,7.2,7,11.5,1.7,4.4,2.5,9,2.5,14.1,0,8.6-1.7,15.7-5.1,21.4-3.4,5.7-7.9,10.3-13.4,13.7-5.5,3.5-11.9,5.9-19,7.4-7.2,1.5-14.4,2.2-21.8,2.2h-56.5v-156.8ZM443.4,974.3h25.5c9.3,0,16.3-1.8,20.9-5.4,4.6-3.6,7-8.4,7-14.3s-2.4-11.8-7.2-15.2c-4.8-3.3-12.5-5-23.2-5h-23v39.9ZM443.4,1043.4h25.7c3.5,0,7.3-.2,11.4-.7,4.1-.4,7.8-1.5,11.2-3.1,3.4-1.6,6.2-4,8.4-7,2.2-3,3.3-7.1,3.3-12.3,0-8.3-2.7-14-8.1-17.3-5.4-3.3-13.8-4.9-25.1-4.9h-26.8v45.2Z"/>
                    <path class="st0" d="M615.9,989.6c0-12.6,2.1-24,6.2-34.2,4.1-10.3,9.9-19,17.2-26.2,7.3-7.2,16-12.8,26.1-16.7,10.1-3.9,21.2-5.9,33.3-5.9,12.3-.1,23.5,1.7,33.7,5.4,10.2,3.8,19,9.3,26.4,16.5,7.4,7.2,13.1,15.9,17.3,26.1,4.1,10.2,6.2,21.6,6.2,34.1s-2.1,23.4-6.2,33.4c-4.1,10-9.9,18.7-17.3,25.9-7.4,7.2-16.2,12.9-26.4,16.9-10.2,4.1-21.4,6.2-33.7,6.3-12.1,0-23.2-2-33.3-5.9-10.1-3.9-18.8-9.5-26.1-16.6-7.3-7.2-13-15.8-17.2-25.8-4.1-10-6.2-21.2-6.2-33.4ZM645.1,987.8c0,8.4,1.3,16.2,3.9,23.3,2.6,7.1,6.2,13.2,11,18.4,4.7,5.2,10.4,9.2,16.9,12.2,6.6,3,13.9,4.4,22,4.4s15.5-1.5,22.2-4.4c6.6-2.9,12.3-7,17.1-12.2,4.7-5.2,8.4-11.3,11-18.4,2.6-7.1,3.9-14.8,3.9-23.3s-1.3-15.1-3.9-21.9c-2.6-6.8-6.2-12.7-11-17.8-4.7-5.1-10.4-9.1-17.1-12-6.7-2.9-14-4.3-22.2-4.3s-15.5,1.4-22,4.3c-6.6,2.9-12.2,6.9-16.9,12-4.7,5.1-8.4,11-11,17.8-2.6,6.8-3.9,14.1-3.9,21.9Z"/>
                    <path class="st0" d="M875.3,910.5h54.5c7.5,0,14.8.7,21.8,2.1,7,1.4,13.2,3.8,18.7,7.2,5.5,3.4,9.8,8,13.1,13.7,3.3,5.8,4.9,13.1,4.9,21.9s-3.1,20.9-9.4,28.6c-6.3,7.7-15.2,12.3-26.9,14l41.6,69.3h-33.7l-36.3-66.5h-20.4v66.5h-27.9v-156.8ZM924.9,977c4,0,8-.2,12-.6,4-.4,7.6-1.3,11-2.7,3.3-1.4,6-3.5,8.1-6.4,2.1-2.9,3.1-6.9,3.1-12.1s-1-8.3-2.9-11.1c-1.9-2.8-4.4-4.9-7.5-6.3-3.1-1.4-6.5-2.3-10.3-2.8-3.8-.4-7.4-.7-11-.7h-24.2v42.5h21.7Z"/>
                    <path class="st0" d="M1138.9,910.5h24.1l67.6,156.8h-31.9l-14.6-35.9h-68l-14.2,35.9h-31.2l68.2-156.8ZM1174.3,1007.5l-24.2-63.8-24.6,63.8h48.7Z"/>
                    <path class="st0" d="M1331.6,935.8h-48.1v-25.3h124v25.3h-48.1v131.6h-27.9v-131.6Z"/>
                    <path class="st0" d="M1481.1,989.6c0-12.6,2.1-24,6.2-34.2,4.1-10.3,9.9-19,17.2-26.2,7.3-7.2,16-12.8,26.1-16.7,10.1-3.9,21.2-5.9,33.3-5.9,12.3-.1,23.5,1.7,33.7,5.4,10.2,3.8,19,9.3,26.4,16.5,7.4,7.2,13.1,15.9,17.3,26.1,4.1,10.2,6.2,21.6,6.2,34.1s-2.1,23.4-6.2,33.4c-4.1,10-9.9,18.7-17.3,25.9-7.4,7.2-16.2,12.9-26.4,16.9-10.2,4.1-21.4,6.2-33.7,6.3-12.1,0-23.2-2-33.3-5.9-10.1-3.9-18.8-9.5-26.1-16.6-7.3-7.2-13-15.8-17.2-25.8-4.1-10-6.2-21.2-6.2-33.4ZM1510.3,987.8c0,8.4,1.3,16.2,3.9,23.3,2.6,7.1,6.2,13.2,11,18.4,4.7,5.2,10.4,9.2,16.9,12.2,6.6,3,13.9,4.4,22,4.4s15.5-1.5,22.2-4.4c6.6-2.9,12.3-7,17.1-12.2,4.7-5.2,8.4-11.3,11-18.4,2.6-7.1,3.9-14.8,3.9-23.3s-1.3-15.1-3.9-21.9c-2.6-6.8-6.2-12.7-11-17.8-4.7-5.1-10.4-9.1-17.1-12-6.7-2.9-14-4.3-22.2-4.3s-15.5,1.4-22,4.3c-6.6,2.9-12.2,6.9-16.9,12-4.7,5.1-8.4,11-11,17.8-2.6,6.8-3.9,14.1-3.9,21.9Z"/>
                    <path class="st0" d="M1740.4,910.5h27.9v156.8h-27.9v-156.8Z"/>
                    <path class="st0" d="M1869.5,910.5h54.5c7.5,0,14.8.7,21.8,2.1,7,1.4,13.2,3.8,18.7,7.2,5.5,3.4,9.8,8,13.1,13.7,3.3,5.8,4.9,13.1,4.9,21.9s-3.1,20.9-9.4,28.6c-6.3,7.7-15.2,12.3-26.9,14l41.6,69.3h-33.7l-36.3-66.5h-20.4v66.5h-27.9v-156.8ZM1919.1,977c4,0,8-.2,12-.6,4-.4,7.6-1.3,11-2.7,3.3-1.4,6-3.5,8.1-6.4,2.1-2.9,3.1-6.9,3.1-12.1s-1-8.3-2.9-11.1c-1.9-2.8-4.4-4.9-7.5-6.3-3.1-1.4-6.5-2.3-10.3-2.8-3.8-.4-7.4-.7-11-.7h-24.2v42.5h21.7Z"/>
                    <path class="st0" d="M2076.2,910.5h103.9v25.3h-76v38.5h72v25.3h-72v42.5h80v25.3h-107.9v-156.8Z"/>
                    <path class="st0" d="M2404.7,910.5h61.8c10.3,0,20.3,1.6,29.9,4.8,9.6,3.2,18.1,8,25.5,14.5,7.4,6.5,13.3,14.7,17.7,24.5,4.4,9.8,6.7,21.4,6.7,34.7s-2.6,25.1-7.7,34.9c-5.1,9.8-11.7,17.9-19.7,24.4-8.1,6.4-17,11.2-26.8,14.4-9.8,3.2-19.5,4.8-28.9,4.8h-58.5v-156.8ZM2453.4,1042.1c8.7,0,16.9-1,24.7-3,7.8-2,14.5-5.1,20.3-9.3,5.8-4.2,10.3-9.7,13.6-16.4,3.3-6.7,5-14.9,5-24.5s-1.5-17.6-4.4-24.4c-2.9-6.8-7.1-12.3-12.3-16.5-5.2-4.2-11.4-7.3-18.6-9.3-7.2-2-15-3-23.6-3h-25.5v106.3h20.8Z"/>
                    <path class="st0" d="M2638.9,910.5h103.9v25.3h-76v38.5h72v25.3h-72v42.5h80v25.3h-107.9v-156.8Z"/>
                    <path class="st0" d="M2841.1,910.5h54.5c7.5,0,14.8.7,21.8,2.1,7,1.4,13.2,3.8,18.7,7.2,5.5,3.4,9.8,8,13.1,13.7,3.3,5.8,4.9,13.1,4.9,21.9s-3.1,20.9-9.4,28.6c-6.3,7.7-15.2,12.3-26.9,14l41.6,69.3h-33.7l-36.3-66.5h-20.4v66.5h-27.9v-156.8ZM2890.7,977c4,0,8-.2,12-.6,4-.4,7.6-1.3,11-2.7,3.3-1.4,6-3.5,8.1-6.4,2.1-2.9,3.1-6.9,3.1-12.1s-1-8.3-2.9-11.1c-1.9-2.8-4.4-4.9-7.5-6.3-3.1-1.4-6.5-2.3-10.3-2.8-3.8-.4-7.4-.7-11-.7h-24.2v42.5h21.7Z"/>
                    <path class="st0" d="M3048.2,910.5h42.5l41.9,109.9,42.3-109.9h42.1v156.8h-26.6v-130.3h-.4l-47.6,130.3h-19.5l-47.6-130.3h-.4v130.3h-26.6v-156.8Z"/>
                    <path class="st0" d="M3369.8,910.5h24.2l67.6,156.8h-31.9l-14.6-35.9h-68l-14.2,35.9h-31.2l68.2-156.8ZM3405.2,1007.5l-24.1-63.8-24.6,63.8h48.7Z"/>
                    <path class="st0" d="M3562.5,935.8h-48.1v-25.3h124v25.3h-48.1v131.6h-27.9v-131.6Z"/>
                    <path class="st0" d="M3712,989.6c0-12.6,2.1-24,6.2-34.2,4.2-10.3,9.9-19,17.2-26.2,7.3-7.2,16-12.8,26.1-16.7,10.1-3.9,21.2-5.9,33.3-5.9,12.3-.1,23.5,1.7,33.7,5.4,10.2,3.8,19,9.3,26.4,16.5,7.4,7.2,13.1,15.9,17.3,26.1,4.1,10.2,6.2,21.6,6.2,34.1s-2.1,23.4-6.2,33.4c-4.1,10-9.9,18.7-17.3,25.9-7.4,7.2-16.2,12.9-26.4,16.9-10.2,4.1-21.4,6.2-33.7,6.3-12.1,0-23.2-2-33.3-5.9-10.1-3.9-18.8-9.5-26.1-16.6-7.3-7.2-13-15.8-17.2-25.8-4.1-10-6.2-21.2-6.2-33.4ZM3741.2,987.8c0,8.4,1.3,16.2,3.9,23.3,2.6,7.1,6.2,13.2,10.9,18.4,4.7,5.2,10.4,9.2,16.9,12.2,6.6,3,13.9,4.4,22.1,4.4s15.5-1.5,22.2-4.4c6.6-2.9,12.3-7,17-12.2,4.7-5.2,8.4-11.3,11-18.4,2.6-7.1,3.9-14.8,3.9-23.3s-1.3-15.1-3.9-21.9c-2.6-6.8-6.2-12.7-11-17.8-4.7-5.1-10.4-9.1-17-12-6.7-2.9-14-4.3-22.2-4.3s-15.5,1.4-22.1,4.3c-6.6,2.9-12.2,6.9-16.9,12-4.7,5.1-8.4,11-10.9,17.8-2.6,6.8-3.9,14.1-3.9,21.9Z"/>
                    <path class="st0" d="M3971.4,910.5h27.9v131.6h66.7v25.3h-94.6v-156.8Z"/>
                    <path class="st0" d="M4140.4,989.6c0-12.6,2.1-24,6.2-34.2,4.1-10.3,9.9-19,17.2-26.2,7.3-7.2,16-12.8,26.1-16.7,10.1-3.9,21.2-5.9,33.3-5.9,12.3-.1,23.5,1.7,33.7,5.4,10.2,3.8,19,9.3,26.4,16.5,7.4,7.2,13.1,15.9,17.3,26.1,4.1,10.2,6.2,21.6,6.2,34.1s-2.1,23.4-6.2,33.4c-4.2,10-9.9,18.7-17.3,25.9-7.4,7.2-16.2,12.9-26.4,16.9-10.2,4.1-21.4,6.2-33.7,6.3-12.1,0-23.2-2-33.3-5.9-10.1-3.9-18.8-9.5-26.1-16.6-7.3-7.2-13-15.8-17.2-25.8-4.1-10-6.2-21.2-6.2-33.4ZM4169.6,987.8c0,8.4,1.3,16.2,3.9,23.3,2.6,7.1,6.2,13.2,11,18.4,4.7,5.2,10.4,9.2,16.9,12.2,6.6,3,13.9,4.4,22,4.4s15.5-1.5,22.2-4.4c6.6-2.9,12.3-7,17.1-12.2,4.7-5.2,8.4-11.3,10.9-18.4,2.6-7.1,3.9-14.8,3.9-23.3s-1.3-15.1-3.9-21.9c-2.6-6.8-6.2-12.7-10.9-17.8-4.7-5.1-10.4-9.1-17.1-12-6.6-2.9-14-4.3-22.2-4.3s-15.5,1.4-22,4.3c-6.6,2.9-12.2,6.9-16.9,12-4.7,5.1-8.4,11-11,17.8-2.6,6.8-3.9,14.1-3.9,21.9Z"/>
                    <path class="st0" d="M4539.1,1055.8c-19.4,10.3-41,15.5-64.9,15.5s-23.2-2-33.3-5.9c-10.1-3.9-18.8-9.5-26.1-16.6-7.3-7.2-13-15.8-17.2-25.8-4.1-10-6.2-21.2-6.2-33.4s2.1-24,6.2-34.2c4.1-10.3,9.9-19,17.2-26.2,7.3-7.2,16-12.8,26.1-16.7,10.1-3.9,21.2-5.9,33.3-5.9s23.2,1.4,33.7,4.3c10.5,2.9,19.9,8,28.1,15.4l-20.6,20.8c-5-4.9-11.1-8.6-18.3-11.3-7.2-2.7-14.7-4-22.7-4s-15.5,1.4-22,4.3c-6.6,2.9-12.2,6.9-16.9,12-4.7,5.1-8.4,11-11,17.8-2.6,6.8-3.9,14.1-3.9,21.9s1.3,16.2,3.9,23.3c2.6,7.1,6.2,13.2,11,18.4,4.7,5.2,10.4,9.2,16.9,12.2,6.6,3,13.9,4.4,22,4.4s13.8-.7,20-2,11.9-3.4,16.7-6.2v-37h-32.1v-25.2h60v80.2Z"/>
                    <path class="st0" d="M4638.5,910.5h27.9v156.8h-27.9v-156.8Z"/>
                    <path class="st0" d="M4935.6,1067.3h-91.3c-12.3,0-23.6-1.9-34-5.6-10.4-3.8-19.4-9.2-27-16.2-7.6-7-13.6-15.6-18-25.7-4.4-10.1-6.5-21.5-6.5-34.2s2.2-22.1,6.5-31.8c4.4-9.7,10.3-18,17.8-25,7.5-7,16.3-12.5,26.2-16.4,10-3.9,20.6-5.9,31.8-5.9s21.8,2,31.8,5.9c10,3.9,18.7,9.4,26.3,16.4,7.5,7,13.5,15.4,17.8,25,4.3,9.7,6.5,20.3,6.5,31.8s-1,15.5-3,21.8c-2,6.3-4.6,11.8-7.9,16.6-3.3,4.8-6.8,8.8-10.7,12.1-3.9,3.3-7.7,6-11.4,8.2v.4h44.9v22.6ZM4840.6,931.8c-7.5,0-14.5,1.4-20.9,4.1-6.4,2.7-11.9,6.5-16.6,11.4-4.7,4.9-8.3,10.6-11,17.3-2.7,6.6-4,13.9-4,21.7s1.3,15.4,4,22.3c2.7,6.9,6.3,12.8,11.1,17.7,4.7,4.9,10.3,8.8,16.7,11.6,6.4,2.8,13.5,4.2,21.2,4.2s14.8-1.4,21.4-4.2c6.6-2.8,12.2-6.7,16.9-11.6,4.7-5,8.4-10.9,11.1-17.7,2.7-6.9,4-14.3,4-22.3s-1.3-15.1-4-21.7c-2.7-6.6-6.3-12.4-11.1-17.3-4.7-4.9-10.4-8.7-16.9-11.4-6.6-2.7-13.8-4.1-21.8-4.1Z"/>
                    <path class="st0" d="M5146.1,1009.7c0,9.5-1.6,18-4.9,25.6-3.3,7.6-7.7,14.1-13.3,19.4-5.6,5.3-12.3,9.4-19.9,12.3-7.7,2.9-16.1,4.3-25,4.3s-17.3-1.4-25-4.3c-7.7-2.9-14.4-7-20-12.3-5.7-5.3-10.1-11.8-13.3-19.4-3.2-7.6-4.7-16.1-4.7-25.6v-99.2h27.9v98.4c0,3.8.6,7.9,1.8,12.1,1.2,4.2,3.1,8.1,5.9,11.6,2.7,3.5,6.3,6.5,10.8,8.7,4.5,2.3,10.1,3.4,16.7,3.4s12.2-1.2,16.7-3.4c4.5-2.3,8.1-5.2,10.8-8.7,2.7-3.5,4.7-7.4,5.9-11.6,1.2-4.2,1.8-8.2,1.8-12.1v-98.4h27.9v99.2Z"/>
                    <path class="st0" d="M5246.9,910.5h103.9v25.3h-76v38.5h72v25.3h-72v42.5h80v25.3h-107.9v-156.8Z"/>
                  </g>
                </svg>
          </a>
      </div>
        <div class="col-6" style="padding-left: 20%">
          <a class="logonaosclubresponsive" href="https://naosclub.bioderma.es"><img class="icononaosclub" src="../../../assets/images/naosclubiconobioderma.png"/></a>
          <a class="hide-on-mobile-tablet" href="https://www.bioderma.es/la-empresa/bioderma-la-marca-dermatologica-de-naos"><img src="../../../assets/images/icononaosbioderma.PNG"/></a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <button class="navbar-toggler botonmenuresponsive" type="button" data-toggle="collapse" data-target="#opciones">
              <span class="navbar-toggler-icon"></span>
            </button>

            <!-- enlaces escritorio-->
            <div class="collapse navbar-collapse hide-on-mobile-tablet" style="justify-content: center !important" id="opciones">
              <ul class="navbar-nav" style="font-size: 14px !important;font-weight: lighter">
                <li class="nav-item optmenu">
                  <a class="nav-link textmenu cursorpointer" href="https://www.bioderma.es/higiene" (mouseover)="this.showsubmenu=true;this.idsubmenucontent=1;">HIGIENE</a>
                </li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu">
                  <a class="nav-link textmenu cursorpointer" href="https://www.bioderma.es/cuidados" (mouseover)="this.showsubmenu=true;this.idsubmenucontent=2;">CUIDADOS</a>
                </li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu">
                  <a class="nav-link textmenu cursorpointer" href="https://www.bioderma.es/proteccion-solar" (mouseover)="this.showsubmenu=true;this.idsubmenucontent=3;">PROTECCIÓN SOLAR</a>
                </li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu">
                  <a class="nav-link textmenu cursorpointer" href="https://www.bioderma.es/bebe" (mouseover)="this.showsubmenu=true;this.idsubmenucontent=4;">BEBÉ</a>
                </li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu">
                  <a class="nav-link textmenu cursorpointer" href="https://www.bioderma.es/nuestros-productos" (mouseover)="this.showsubmenu=true;this.idsubmenucontent=5;">NUESTROS PRODUCTOS</a>
                </li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu">
                  <a class="nav-link textmenu cursorpointer" href="https://www.bioderma.es/tu-piel" (mouseover)="this.showsubmenu=true;this.idsubmenucontent=6;">TU PIEL</a>
                </li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu">
                  <a class="nav-link textmenu cursorpointer" href="https://www.bioderma.es/disfruta-de-todas-nuestras-promociones" (mouseover)="this.showsubmenu=true;this.idsubmenucontent=7;">PROMOCIONES</a>
                </li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu">
                  <a class="nav-link textmenu cursorpointer" href="https://www.bioderma.es/asesoramiento" (mouseover)="this.showsubmenu=true;this.idsubmenucontent=8;">ASESORAMIENTO</a>
                </li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu"> &nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li class="nav-item optmenu">
                  <a class="nav-link textmenu cursorpointer" href="https://www.bioderma.es/la-empresa" (mouseover)="this.showsubmenu=true;this.idsubmenucontent=9;">LA EMPRESA</a>
                </li>
                <!-- <li class="nav-item optmenu">
                  <a class="nav-link textmenu cursorpointer" href="https://naosclub.bioderma.es">NAOS CLUB</a>
                </li> -->
              </ul>
            </div>
            <!-- enlaces responsive-->
            <div class="collapse navbar-collapse hide-on-desktop" id="opciones" style="background-color: #F4F4F5">
                <ul class="navbar-nav" style="padding-left: 18% !important;font-size: 14px !important;font-weight: lighter ">
                  <li class="nav-item optmenu cursorpointer">
                    <a class="nav-link textmenu" href="https://www.bioderma.es/higiene" role="button">HIGIENE</a>
                  </li>
                  <li class="nav-item optmenu cursorpointer">
                    <a class="nav-link textmenu" href="https://www.bioderma.es/cuidados" role="button">CUIDADOS</a>
                  </li>
                  <li class="nav-item optmenu cursorpointer">
                    <a class="nav-link textmenu" href="https://www.bioderma.es/proteccion-solar" role="button">PROTECCIÓN SOLAR</a>
                  </li>
                  <li class="nav-item optmenu cursorpointer">
                    <a class="nav-link textmenu" href="https://www.bioderma.es/bebe" role="button">BEBÉ</a>
                  </li>
                  <li class="nav-item optmenu cursorpointer">
                    <a class="nav-link textmenu" href="https://www.bioderma.es/nuestros-productos" role="button">NUESTROS PRODUCTOS</a>
                  </li>
                  <li class="nav-item optmenu cursorpointer">
                    <a class="nav-link textmenu" href="https://www.bioderma.es/tu-piel" role="button">TU PIEL</a>
                  </li>
                  <li class="nav-item optmenu cursorpointer">
                    <a class="nav-link textmenu" href="https://www.bioderma.es/disfruta-de-todas-nuestras-promociones" role="button">PROMOCIONES</a>
                  </li>
                  <li class="nav-item optmenu cursorpointer">
                    <a class="nav-link textmenu" href="https://www.bioderma.es/asesoramiento" role="button">ASESORAMIENTO</a>
                  </li>
                  <li class="nav-item optmenu cursorpointer">
                    <a class="nav-link textmenu" href="https://www.bioderma.es/la-empresa" role="button">LA EMPRESA</a>
                  </li>
                  <!-- <li class="nav-item optmenu cursorpointer">
                    <a class="nav-link textmenu" href="https://naosclub.bioderma.es">NAOS CLUB</a>
                  </li> -->
                </ul>
              </div>
          </nav>
        </div>
      </div>
    </div>
  </div>

